<template>
    <div class="c-player__live">
        <!-- 音视频画面 -->
        <div
             v-if="bolRender"
            class="live-item"
            :class="computedItemClass(MediaType.Video)"
            @click.stop="handleItem(MediaType.Video)"
        >
            <c-player-video
                :source="videoSource"
                :startTime="startTime"
            ></c-player-video>
            <div class="icon-close" @click.stop="handleClose(MediaType.Video)">
                <i class="icon-font">&#xe6b8;</i>
            </div>
        </div>
        <!-- 演示文稿 -->
        <div
            class="live-item"
            :class="computedItemClass(MediaType.Pdf)"
            v-if="pdfData && pdfData.pid"
            @click.stop="handleItem(MediaType.Pdf)"
        >
            <c-player-pdf
                :allowTouchMove="isLiveRoadshow || pdfData.syncppt == 0"
            ></c-player-pdf>
            <div class="icon-close" @click.stop="handleClose(MediaType.Pdf)">
                <i class="icon-font">&#xe6b8;</i>
            </div>
        </div>
    </div>
</template>
<script>
import { mapMutations, mapState } from "vuex";
import { LiveMode, MediaType } from "./c-player.js";
import { getGoldSunUserId, saveRecordSave, getRecordSave } from '@/utils/storage';
import cPlayerBg from "./c-player__bg.vue";
import cPlayerVideo from "./c-player__video.vue";
import cPlayerPdf from "./c-player__pdf.vue";
import "./icon/iconfont.css";

export default {
    data() {
        return {
            MediaType,

            bolRender: false,
            videoSource: "",

            startTime: 0,
            currTime: 0,
        };
    },

    components: {
        cPlayerBg,
        cPlayerVideo,
        cPlayerPdf,
    },

    computed: {
        ...mapState("live", [
            "roadshowData",
            "isLiveRoadshow",
            "isLive",
            "pdfData",
            "liveMode",
            "chapterTime",
        ]),

        computedItemClass() {
            return (mediaType) => [
                mediaType == this.$player.screenType
                    ? "large-player"
                    : "small-player",
                mediaType == this.$player.closeWin ? "hide-player" : "",
                mediaType == MediaType.Video && this.liveMode == LiveMode.Audio
                    ? "hide"
                    : "",
            ];
        }
    },

    watch: {
        chapterTime(v) {
            // 播放器播放进度回调也会触发这个值的改变，所以需要用变量类型区分是用户点击章节，还是进度改变
            if (typeof v === "string") {
                this.$player.dispatchEvent(
                    "progress",
                    Number((Number(v) / 1000).toFixed(2))
                );

                this.$player.dispatchEvent("isClick", true);
            }
        },
        "pdfData.pid"(val) {
            this.handleItem(val ? MediaType.Pdf : MediaType.Video);
        },
        roadshowData: {
            handler() {
                this.initData();
            },
            deep: true,
        },
    },

    created() {
        this.onEvent();
    },

    beforeDestroy() {
        this.offEvent();
        clearTimeout(this.waitTimeTimer);
    },

    methods: {
        ...mapMutations("live", ["setRecordCurrTime"]),

        initData() {
            this.videoSource =
                this.roadshowData.playurl ||
                this.roadshowData.pageAudioUrl ||
                "";
            this.$player.videoSource = this.videoSource;
            this.startTime = this.getRecordSaveTime();

            // 有 PPT 优先显示 PPT
            this.handleItem(
                this.pdfData && this.pdfData.pid
                    ? MediaType.Pdf
                    : MediaType.Video
            );

            this.$player.dispatchEvent("showBigPlay");
        },

        onEvent() {
            this.$player.addListener("startRender", this.startRender);
            this.$player.addListener("error", this.onError);
            this.$player.addListener("play", this.onPlay);
            this.$player.addListener("progress", this.onProgress);
            this.$player.addListener("timeupdate", this.onTimeupdate);
        },

        offEvent() {
            this.$player.removeListener("startRender", this.startRender);
            this.$player.removeListener("error", this.onError);
            this.$player.removeListener("play", this.onPlay);
            this.$player.removeListener("progress", this.onProgress);
            this.$player.removeListener("timeupdate", this.onTimeupdate);

            clearInterval(this.recordSaveTimer);
        },

        startRender(isLive) {
            this.bolRender = !this.isLiveRoadshow || isLive || this.isLive;
        },

        onError(code, msg) {
            if (this.isLiveRoadshow && this.isLive) {
                setTimeout(() => {
                    this.$player.dispatchEvent("refresh");
                }, 3000);
            } else {
                this.$showToast(msg);
            }
        },

        onPlay(isPlay) {
            isPlay
                ? this.handleRecordSave()
                : clearInterval(this.recordSaveTimer);
        },

        onProgress(time) {
            this.recordSaveTime(time);
        },

        onTimeupdate(currTime) {
            this.currTime = currTime;
            if (!this.isLiveRoadshow) {
                this.handleTimeUpdate(currTime);
            }
            // 回放状态下需要同步章节位置
            if (
                !this.isLiveRoadshow &&
                this.roadshowData.chapterlist &&
                this.roadshowData.chapterlist.length
            ) {
                this.setChapterTime(currTime);
            }
        },

        handleItem(val) {
            if (this.$player.screenType == val) {
                this.$player.dispatchEvent("isClick");
            } else {
                this.$player.screenType = val;
                this.$player.closeWin = "";

                this.$player.dispatchEvent("resize");
            }
        },

        handleClose(type) {
            this.$player.closeWin = type;

            this.$player.dispatchEvent("isClick", true);
        },

        // 获取历史播放记录
        getRecordSaveTime() {
            const rid = this.roadshowData.id;
            const userId = getGoldSunUserId();
            const recordSave = (getRecordSave() || []).find((item) => {
                return item.id == rid && item.uid == userId;
            });
            const localCurrTime = recordSave ? recordSave.recordCurrentTime : 0;
            return localCurrTime || 0.1;
        },

        // 记录回放播放时间点
        recordSaveTime(time) {
            let rid = this.roadshowData.id;
            let userId = getGoldSunUserId();
            console.log("liveroom.watchrecord", rid, time);
            // this.$axios.post(api.liveroom.watchrecord, {
            //     roadshowid: rid,
            //     anchorpoint: (Number(time) * 1000).toString().split(".")[0],
            // }).catch(() => { })
            let recordSave = getRecordSave();
            let currentSave = {};
            let isExist = false;
            if (recordSave) {
                currentSave = {
                    id: rid,
                    uid: userId,
                    recordCurrentTime: time,
                    timeStamp: new Date().getTime(),
                };
                recordSave.forEach((o, i) => {
                    if (o.id == rid && o.uid == userId) {
                        isExist = true;
                        o.recordCurrentTime = time;
                        o.timeStamp = new Date().getTime();
                    }
                });
                if (!isExist) {
                    recordSave.push(currentSave);
                }
            } else {
                recordSave = [];
                currentSave = {
                    id: rid,
                    uid: userId,
                    recordCurrentTime: time,
                    timeStamp: new Date().getTime(),
                };
                recordSave.push(currentSave);
            }
            saveRecordSave(recordSave);
        },

        handleRecordSave() {
            clearInterval(this.recordSaveTimer);

            if (this.isLiveRoadshow) return;
            this.recordSaveTimer = setInterval(() => {
                this.recordSaveTime(parseInt(this.currTime));
            }, 30 * 1000);
        },

        handleTimeUpdate(currTime) {
            this.setRecordCurrTime(Number(currTime * 1000));
        },

        setChapterTime(currTime) {
            let len = this.roadshowData.chapterlist.length;
            this.roadshowData.chapterlist.forEach((item, index) => {
                if (index < len - 1) {
                    let next = this.roadshowData.chapterlist[index + 1];
                    if (
                        currTime * 1000 > Number(item.nodetime) &&
                        currTime * 1000 < Number(next.nodetime)
                    ) {
                        this.$store.commit(
                            "setChapterTime",
                            Number(item.nodetime)
                        );
                    }
                } else if (currTime * 1000 > Number(item.nodetime)) {
                    this.$store.commit("setChapterTime", Number(item.nodetime));
                }
            });
        }
    },
};
</script>
<style lang="less" scoped>
.hide {
	display: none;
}
.c-player__live {
    .live-item {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        &.small-player {
            top: auto;
            left: auto;
            bottom: 0;
            right: 0;
            width: 1.6rem;
            height: 0.9rem;
            z-index: 10;

            /deep/ .pdf-page {
                display: none;
            }

            .icon-close {
                display: block;
            }
        }

        &.hide-player {
            opacity: 0;
            bottom: -0.9rem;
        }

        .icon-close {
            position: absolute;
            top: 0;
            right: 0;
            width: 0.32rem;
            height: 0.32rem;
            overflow: hidden;
            z-index: 10;
            text-align: right;
            padding: 0.04rem;
            display: none;

            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 0.32rem;
                height: 0.32rem;
                border-radius: 0 0 0 0.32rem;
                background-color: #000000;
                opacity: 0.5;
                z-index: -1;
            }
        }

        .icon-font {
            font-size: 0.16rem;
            color: #ffffff;
        }
    }
}
</style>
