<template>
    <div class="c-wrapper center" @click.stop>
        <div class="c-dialog__container">
            <div class="content">
                <div v-if="isHtml" v-html="title"></div>
                <template v-else>{{ title }}</template>
            </div>
            <div class="footer">
                <div class="cancel-btn" v-if="showCancel" @click="cancel">
                    {{cancelText}}
                </div>
                <div class="confirm-btn" :style="`color:${confirmTextColor || '#ff4545'}`" @click="confirm">
                    {{confirmText}}
                </div>
            </div>
            <div class="close-btn" v-if="showClose" @click="close">
                <div class="iconfont iconclose"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            title: "",
            showCancel: false,
            cancelText: "取消",
            confirmText: "确定",
            isHtml: false,
            showClose: false,
            confirmTextColor: "#ff4545",
        };
    },

    methods: {
        confirm() { },
        cancel() { },
        close() {},
    },
};
</script>

<style scoped lang="less">
.c-dialog__container {
    position: relative;
    background: #fff;
    width: 2.9rem;
    border-radius: 0.08rem;
    .close-btn{
        position: absolute;
        right: .09rem;
        top: .09rem;
        height: .2rem;
        width: .2rem;
        color: #C5C5C5;
        line-height: .2rem;
        .iconclose{
            font-size: .2rem;
        }
    }
    .content {
        padding: 0.23rem 0.2rem;
        font-size: 0.15rem;
        color: #333333;
        line-height: 0.24rem;
        text-align: center;
    }
    .footer {
        border-top: 0.01rem solid #ebebeb;
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        font-size: 0.15rem;
        line-height: 0.21rem;
    }
    .cancel-btn {
        padding: 0.12rem 0;
        width: 100%;
        color: #333333;
    }
    .confirm-btn {
        padding: 0.12rem 0;
        width: 100%;

    }
}
</style>