// const SRS_TRIPARTITE = '/srs/tripartite';
const SRS_TRIPARTITE= 'https://sparkling-disk-b39e.marygay202111.workers.dev/proxy/https://devyjfw.guosen.com.cn/srs/tripartite'
export const goldSun = {
    // 通过路演会议id查询对应路演详情（id）
    getExternal: SRS_TRIPARTITE + '/meet/external/get',
    // 查询路演标签信息
    getLabel: SRS_TRIPARTITE + '/meet/external/getLabel',
    // 获取相关会议数据
    getSimilarData: SRS_TRIPARTITE + '/meet/external/similar/data',
    // 获取ppt同步视频数据	
    getPptInfo: SRS_TRIPARTITE + '/meet/external/pptInfo',
    // 校验身份信息	
    checkAccess: SRS_TRIPARTITE + '/meet/external/checkAccess',
    // 关注/取关分析师	
    followAnalyst: SRS_TRIPARTITE + '/meet/external/followAnalyst',
    // 预约/取消预约会议	
    followMeeting: SRS_TRIPARTITE + '/meet/external/followMeeting',
    // 入会离开会播放回传	
    joinCallback: SRS_TRIPARTITE + '/meet/external/joinCallback'
}

