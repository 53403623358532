<template>
    <div
        class="c-player"
        :class="computedClass"
        :style="{
            width:
                $player.isFullscreen && !$player.isOrientation
                    ? innerHeight + 'px'
                    : '100%',
        }"
    >
        <div class="c-player__body" @click="handlePlayer">
            <!-- 背景 -->
            <c-player-bg></c-player-bg>
            <!-- 控制栏 -->
            <c-player-ctrl></c-player-ctrl>
        </div>
        <c-player-live></c-player-live>
    </div>
</template>
<script>
import { mapMutations, mapState } from "vuex";

import cPlayerLive from "./c-player__live.vue";
import cPlayerCtrl from "./c-player__ctrl.vue";
import cPlayerBg from "./c-player__bg.vue";
import { goldSun } from '@/axios/interface'
import { getGoldSunUserId } from '@/utils/storage';

export default {
    props: {
        data: {
            type: Object,
            default: () => {
                return {};
            },
        },
        pdfPages: {
            type: Array,
            default: () => {
                return [];
            },
        },
    },
    data() {
        return {
            playerLiveMode: null,
            showCtrl: false,
            innerHeight: window.innerHeight,
        };
    },
    components: {
        cPlayerLive,
        cPlayerCtrl,
        cPlayerBg,
    },
    computed: {
        ...mapState("live", [
            "roadshowData",
            "pdfData",
            "verifyPassword",
            "raiseStatus",
            "user",
        ]),

        computedClass() {
            return {
                fullscreen: this.$player.isFullscreen,
                orientation: this.$player.isOrientation,
                "is-ctrl": this.showCtrl,
            };
        },
        roadShowDataAndPdf() {
            return {
                data: this.data,
                pdfPages: this.pdfPages,
            };
        },
    },
    watch: {
        roadShowDataAndPdf: {
            handler() {
                this.initData();
            },
            deep: true,
        },
    },
    created() {
        // this.initData();
        this.onEvent();
        this.orientationchange();
    },

    beforeDestroy() {
        this.offEvent();
        clearInterval(this.staticTimer);
        clearInterval(this.onlineTimer);

        let appEl = document.getElementById("app");
        appEl.classList.remove("fullscreen");
        appEl.classList.remove("orientation");
    },

    methods: {
        ...mapMutations("live", [
            "setPdfData",
            "setPdfList",
            "setRoadshowData",
            "setLiveMode"
        ]),
        initData() {
            let pdfData = null;
            const pdfList =
                this.pdfPages && this.pdfPages.length
                    ? this.pdfPages[0].attachmentPicInfoList || []
                    : [];
            if (pdfList && pdfList.length) {
                pdfData = {
                    pid: this.pdfPages[0].attachmentId,
                    url: "",
                    page: 1,
                    syncppt: this.data.syncppt || 0,
                };
            }
            this.setRoadshowData(this.data);
            this.setPdfData(pdfData);
            this.setPdfList(pdfList);
            this.setLiveMode(+this.data.eventWay);
        },

        onEvent() {
            window.addEventListener(
                "orientationchange",
                this.orientationchange
            );
            window.addEventListener("resize", this.onResize);
            this.$player.addListener("showCtrl", this.onShowCtrl);
            this.$player.addListener("play", this.onPlay);
            this.$player.addListener("timeupdate", this.onTimeUpdate);
            this.$player.addListener("joinError", this.onJoinError);
        },

        offEvent() {
            window.removeEventListener(
                "orientationchange",
                this.orientationchange
            );
            window.removeEventListener("resize", this.onResize);
            this.$player.removeListener("showCtrl", this.onShowCtrl);
            this.$player.removeListener("play", this.onPlay);
            this.$player.removeListener("timeupdate", this.onTimeUpdate);
            this.$player.removeListener("joinError", this.onJoinError);
        },

        orientationchange() {
            let val = window.orientation == 90 || window.orientation == -90;

            this.$player.isOrientation = val;
            this.$player.dispatchEvent("orientation", val);

            let appEl = document.getElementById("app");
            val
                ? appEl.classList.add("orientation")
                : appEl.classList.remove("orientation");

            // 使用iframe嵌入的方式需要通知
            if (this.$player.isPlayer && window.self !== window.parent) {
                window.parent.postMessage(
                    {
                        event: "orientation",
                        value: val,
                    },
                    "*"
                );
            }
        },

        onResize() {
            this.innerHeight = window.innerHeight;
        },

        onShowCtrl(val) {
            this.showCtrl = val;
        },

        onPlay(isPlay) {
            isPlay
                ? this.statisticsPlayTime()
                : clearInterval(this.staticTimer);
        },

        onTimeUpdate(currTime) {
            this.syncPdf(currTime);
        },

        syncPdf(currTime) {
            let time = parseInt(currTime) * 1000;

            // 1、不存在PDF文件
            // 2、不支持PDF同步
            // 3、不存在PDF同步记录
            if (
                !this.pdfData ||
                this.pdfData.syncppt === 0 ||
                !this.pdfPages ||
                !this.pdfPages.length
            ) {
                return;
            }

            const obj = this.pdfPages.find((item) => {
                return Number(item.btime) <= time && Number(item.etime) > time;
            });

            if (obj) {
                if (obj.page !== this.pdfData.page) {
                    this.setPdfData(Object.assign({}, this.pdfData, { page: obj.page }));
                }
                // let currPage = obj;

                // if (
                //     currPage.attachment &&
                //     currPage.attachment !== this.pdfData.url
                // ) {
                //     this.setPdfData({
                //         pid: currPage.attachmentid,
                //         url: currPage.attachment,
                //         page: currPage.page,
                //     });
                // } else if (currPage.page !== this.pdfData.page) {
                //     this.setPdfData(
                //         Object.assign({}, this.pdfData, { page: currPage.page })
                //     );
                // }
            }
        },

        onJoinError(data) {
            this.$emit("joinError", data);
        },
        handlePlayer() {
            this.$player.dispatchEvent("isClick");
        },

        statisticsPlayTime() {
            if(this.staticTimer) clearInterval(this.staticTimer);
            const func = (eventtype) => {
                console.log("statistic_roadshow", {
                    eventtype,
                    roadshowtype: "record",
                    roadshowid: this.roadshowData.routerId,
                });
                this.$axios.post(goldSun.joinCallback, {
                    action: eventtype === 'playbegin' ? 0 : 1,
                    id: this.roadshowData.routerId,
                    mobile: getGoldSunUserId()
                }, { bodyType: 'form' })
                return func;
            };

            this.staticTimer = setInterval(
                func("playbegin"),
                30 * 1000,
                "playing"
            );
        },
    },
};
</script>
<style lang="less" scoped>
.c-player {
    position: relative;
    width: 100%;
    background-color: #ffffff;

    &.fullscreen:not(.orientation) {
        position: fixed;
        top: 0;
        left: 100%;
        transform: rotate(90deg);
        transform-origin: top left;
        z-index: 99;
        width: 100vh;
        height: 100vw;

        .c-player__body {
            height: 100vw;
        }

        /deep/ .c-player__rtc {
            height: 0;

            .media-list {
                height: 0;
                overflow: hidden;
            }

            .media-item {
                &.screen {
                    height: 100vw;
                }

                .video-user {
                    left: 0.16rem;
                    bottom: 0.16rem;
                }
            }

            .c-player__bisection {
                height: 100vw;
            }

            .raise-hand {
                width: 3.43rem;
                left: 50%;
                transform: translateX(-50%);
            }

            .btn-speak {
                bottom: 0.4rem;
                right: 0.22rem;
            }
        }

        /deep/ .c-player__pdf .pdf-swiper {
            width: 100vw;
            height: 100vh;
            transform: rotate(-90deg) translate(-50%, -50%);
            transform-origin: top center;
        }

        /deep/ .small-player {
            width: 1.6rem;
            height: 0.9rem;

            .c-player__pdf .pdf-swiper {
                width: 0.9rem;
                height: 1.6rem;
            }
        }

        /deep/ .c-player__bisection {
            .swiper {
                width: 100vw;
                height: 100vh;
                transform: rotate(-90deg) translate(-50%, -50%);
                transform-origin: top center;
            }

            .swiper-container-vertical > .swiper-pagination-bullets {
                left: 10px;
                bottom: 50%;
                right: auto;
                top: auto;
            }

            .bisection-box {
                transform: rotate(90deg) translate3d(0, 0, 0);
            }
        }

        /deep/ .c-player__demo .c-player__agora {
            width: 100vh;
            height: 100vw;
        }
    }

    &.orientation {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 999;

        .c-player__body {
            height: 100vh;
        }

        /deep/ .c-player__rtc {
            .media-item.screen {
                height: 100%;
            }

            .media-audio {
                display: none;
            }

            .media-desc {
                display: none;
            }

            .c-player__bisection {
                height: 100%;
            }

            .raise-hand {
                width: 3.43rem;
                left: 50%;
                transform: translateX(-50%);
            }

            .btn-speak {
                bottom: 0.4rem;
                right: 0.22rem;
            }
        }
    }
}

.c-player__body {
    position: relative;
    width: 100%;
    height: 2.11rem;
    background: url("./images/multimedia_bg.gif");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
</style>
