import Vue from "vue";

import cToast from './c-toast.vue';
import cModal from './c-modal.vue';

const messageItems = [];

const instance = function (component, data, methods) {
    let MessageConstructor = Vue.extend(component);
    let message = new MessageConstructor({
        data,
        methods
    })
    message.$mount();
    document.body.appendChild(message.$el);
    return message;
}

const close = function (message, timestamp = 0) {
    if (timestamp) {
        let index = messageItems.findIndex(item => item.timestamp === timestamp);
        index >= 0 && messageItems.splice(index, 1);
    }
    document.body.removeChild(message.$el);
}

export default {
    install(Vue) {
        Vue.prototype.$closeAll = function () {
            if (messageItems.length === 0) {
                return
            }
            for (let index = messageItems.length - 1; index >= 0; index--) {
                close(messageItems[index].message, messageItems[index].timestamp);
            }
        }
        Vue.prototype.$showToast = function (title = "", options = {}) {
            let message = instance(cToast, {
                title
            });
            setTimeout(() => {
                close(message);
                message = null;
            }, options.timeout || 2000);

            return Promise.resolve();
        }
        Vue.prototype.$showModal = function (title = "", options = {}) {
            return new Promise((resolve, reject) => {
                let timestamp = Date.now();
                let message = instance(cModal, {
                    title,
                    ...options
                }, {
                    confirm: res => {
                        close(message, timestamp);
                        message = null;
                        resolve(res);
                    },
                    cancel: res => {
                        close(message, timestamp);
                        message = null;
                        reject(res);
                    },
                    close: res => {
                        close(message, timestamp);
                        message = null;
                    }
                });
                messageItems.push({
                    timestamp,
                    message
                })
            })
        }
    }
};