<template>
    <div class="c-wrapper center blank" @click.stop>
        <div class="c-tooltip-container">
            {{title}}
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            title: "",
        };
    },
};
</script>

<style scoped lang="less">
.c-tooltip-container {
    max-width: 2.5rem;
    margin: 0 auto;
    background: rgba(0, 0, 0, 0.6);
    padding: 0.125rem 0.15rem;
    border-radius: 0.04rem;
    font-size: 0.17rem;
    color: #fff;
    line-height: 0.24rem;
    text-align: center;
}
</style>