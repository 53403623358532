<template>
    <div class="c-player__ctrl" :class="{'screen': $player.isFullscreen || $player.isOrientation}">
        <!-- 居中大播放按钮 -->
        <div class="btn-play__big" v-if="showBigPlay" @click="handleStart">
            <div class="icon"><i class="icon-font">&#xe6ae;</i></div>
        </div>

        <!-- 顶部控制栏 -->
        <transition>
            <div class="control-top" v-show="showCtrl">
                <!-- 返回按钮 -->
                <div class="control-top__left">
                    <div class="control-item back" v-show="$player.isFullscreen && !$player.isOrientation">
                        <i class="icon-font" @click="handleFullscreen(false)">&#xe6b7;</i>
                        <span class="title">{{ roadshowData.title }}</span>
                    </div>
                </div>
                <div class="control-top__right">
                    <!-- 倍速播放 -->
                    <div class="control-item" v-show="!isLiveRoadshow && !$player.isPlayer && !($player.isFullscreen || $player.isOrientation)" @click="showRate = true">
                        <i class="icon-font" v-html="calcRateIcon"> </i>
                    </div>
                    <!-- 快进快退 -->
                    <div class="control-item" v-show="!isLiveRoadshow && !($player.isFullscreen || $player.isOrientation)" @click="handleSpeed()">
                        <i class="icon-font">&#xe6ad;</i>
                    </div>
                    <!-- 静音按钮 -->
                    <div class="control-item" v-if="isLiveRoadshow" @click.stop="handleMute">
                        <i class="icon-font">{{ isMute ? '&#xe6b0;' : '&#xe6a7;' }}</i>
                        <div class="txt" v-if="$player.isFullscreen || $player.isOrientation">声音</div>
                    </div>
                    <!-- 消息 -->
                    <div class="control-item" :class="{'active': $player.showMessage}" v-if="isLiveRoadshow && ($player.isFullscreen || $player.isOrientation)" @click.stop="handleMessage">
                        <i class="icon-font">&#xe7b7;</i>
                        <div class="txt">消息</div>
                        <div class="message-news" v-if="$player.messageNewCount">{{ $player.messageNewCount }}</div>
                    </div>

                </div>
            </div>
        </transition>

        <!-- 底部控制栏 -->
        <transition name="video-fade">
            <div class="control-bottom" v-if="!showBigPlay" v-show="showCtrl && !(isLiveRoadshow && ($player.isFullscreen || $player.isOrientation))" @click.stop="handleCtrl(true)">
                <div class="control-bottom__left">
                    <!-- 播放按钮 -->
                    <div class="control-item play" v-if="!isLiveRoadshow || mode == PlayerMode.Live">
                        <i class="icon-font" v-if="isLoaded || isLiveRoadshow" @click="handlePlay">{{ isPlay ? '&#xe6a6;' : '&#xe6ae;' }}</i>
                        <svg class="icon-svg" aria-hidden="true" v-else>
                            <use xlink:href="#icon-mokuaijiazai"></use>
                        </svg>
                    </div>
                    <!-- 进度条 -->
                    <div class="progress-box" v-if="!isLiveRoadshow">
                        <div class="currtime">{{ calcTime(progress) }}</div>
                        <div class="progress-bar" ref="progress" @click="touchProgress">
                            <div class="progress total"></div>
                            <div class="progress buffered" :style="{ 'width': bufferedPercent * 100 + '%' }"></div>
                            <div class="progress loaded" :style="{ 'width': calcDragLeft }"></div>
                            <div class="progress drag" ref="drag" :style="{ 'left': calcDragLeft }" @mousedown="touchDragStart" @touchstart="touchDragStart" @mousemove="touchDragMove" @touchmove="touchDragMove" @mouseup="touchDragEnd" @touchend="touchDragEnd"></div>
                        </div>
                        <div class="duration">{{ calcTime(duration) }}</div>
                    </div>
                </div>

                <!-- 快退 -->
                <div class="control-item" v-if="!isLiveRoadshow && ($player.isFullscreen || $player.isOrientation)" @click="setSpeed(-15)">
                    <i class="icon-font">&#xe6b2;</i>
                </div>
                <!-- 快进 -->
                <div class="control-item" v-if="!isLiveRoadshow && ($player.isFullscreen || $player.isOrientation)" @click="setSpeed(15)">
                    <i class="icon-font">&#xe6b1;</i>
                </div>
                <!-- 倍速播放 -->
                <div class="control-item" v-if="!isLiveRoadshow && ($player.isFullscreen || $player.isOrientation)" @click.stop="handleRate">
                    <span>{{ calcRateText }}</span>
                </div>

                <!-- 展开/隐藏大小窗 -->
                <div class="control-item" v-if="(!isLiveRoadshow || mode === PlayerMode.Live) && $player.closeWin">
                    <i class="icon-font" @click="handleWin">{{ $player.closeWin == MediaType.Video ? '&#xe6ac;' : '&#xe6af;' }}</i>

                    <div class="close-win-tips" v-if="showCloseWinTips" @click.stop>
                        <span>{{ $player.closeWin == MediaType.Video ? '点击可查看视频' : '点击可查看文档' }}</span>
                    </div>
                </div>
                <!-- 全屏按钮 -->
                <div class="control-item" v-show="!($player.isFullscreen || $player.isOrientation)" @click="handleFullscreen(true)">
                    <i class="icon-font">&#xe6a8;</i>
                </div>
            </div>
        </transition>

        <transition name="video-fade">
            <div class="control-progress" v-show="!showCtrl && !isLiveRoadshow">
                <div class="progress total"></div>
                <div class="progress buffered" :style="{ 'width': bufferedPercent * 100 + '%' }"></div>
                <div class="progress loaded" :style="{ 'width': calcDragLeft }"></div>
            </div>
        </transition>
        <!-- 快进/快退 -->
        <transition name="video-fade">
            <div class="control-speed" v-show="showSpeed" @click.stop="handleSpeed()">
                <div class="speed-item" @click.stop="setSpeed(-15)">
                    <i class="icon-font">&#xe6b2;</i>
                </div>
                <div class="speed-item" @click.stop="setSpeed(15)">
                    <i class="icon-font">&#xe6b1;</i>
                </div>
            </div>
        </transition>
        <!-- 倍速播放 -->
        <transition name="video-fade">
            <div class="control-rate" v-show="showRate" @click.stop="showRate = false">
                <div class="content">
                    <div class="rate-header">
                        <span>播放倍速</span>
                        <i class="icon-font" @click="showRate = false">&#xe6b8;</i>
                    </div>
                    <div class="rate-list">
                        <div class="rate-item" :class="{'active': item.value === playRate}" v-for="item in playRateList" :key="item.value" @click="setRate(item)">
                            {{ item.label }}
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
import { mapState } from 'vuex'
import { debounce } from '@/utils/api'
import './icon/iconfont.css'
import './icon/iconfont.js'
import { PlayerMode, RaiseStatus, MediaType } from './c-player.js';

export default {
    data() {
        return {
            PlayerMode,
            MediaType,
            RaiseStatus,

            // 显示控制栏
            showCtrl: false,
            // 显示播放按钮
            showBigPlay: false,
            // 加载完成
            isLoaded: false,
            // 播放中
            isPlay: false,
            // 静音
            isMute: false,
            // 倍速播放
            showSpeed: false,
            // 切换PDF
            mediaType: "",
            // 高低延迟切换
            showMode: false,
            // 当前模式
            mode: "",
            // 路演是否支持会议模式
            isWebRtc: false,

            // 显示切换提示
            showCloseWinTips: false,

            // 缓冲进度
            buffered: 0,
            bufferedPercent: 0,
            // 播放时间
            currTime: 0,
            // 进度条时间
            progress: 0,
            // 视频时长
            duration: 0,
            // 播放倍速
            showRate: false,
            playRate: 1,
            playRateList: [
                { label: '0.5X', value: 0.5, icon: '&#xe77b;', text: '0.5X' },
                { label: '0.75X', value: 0.75, icon: '&#xe77c;', text: '0.75X' },
                { label: '1.0X', value: 1, icon: '&#xe77d;', text: '倍速' },
                { label: '1.25X', value: 1.25, icon: '&#xe77a;', text: '1.25X' },
                { label: '1.5X', value: 1.5, icon: '&#xe779;', text: '1.5X' },
                { label: '2.0X', value: 2, icon: '&#xe778;', text: '2.0X' }
            ],

            touchData: null,
        }
    },

    computed: {
        ...mapState('live', ['isLiveRoadshow', "roadshowData", "user", "pdfData", "raiseStatus", 'liveMode']),

        calcRateIcon() {
            return this.playRateList.find(item => item.value == this.playRate).icon;
        },

        calcRateText() {
            return this.playRateList.find(item => item.value == this.playRate).text;
        },

        calcTime() {
            let format = function (num) {
                num = parseInt(num);
                return num < 10 ? '0' + num : num;
            }

            return time => {
                let minute = parseInt(time / 60);
                time = time % 60;
                return format(minute) + ':' + format(time);
            }
        },

        calcDragLeft() {
            return this.duration ? this.progress / this.duration * 100 + '%' : 0;
        }
    },

    watch: {
        showCtrl(val) {
            this.$player.dispatchEvent('showCtrl', val);
        }
    },

    created() {
        this.initData();
        this.onEvent();
    },

    beforeDestroy() {
        this.offEvent();
        clearTimeout(this.ctrlTimer);
    },

    methods: {
        initData() {
            // 初始化播放模式
            this.isWebRtc = !!(this.roadshowData.liveRoomState >> 0 & 1);
            this.showCloseWinTips = !localStorage.getItem("HIDE_CLOSE_WIN_TIPS");
            if (this.showCloseWinTips && (!this.isLiveRoadshow || mode === PlayerMode.Live) && this.pdfData && this.pdfData.pid) {
                this.showCtrl = true;
            }
        },

        onEvent() {
            this.$player.addListener("loadstart", this.onLoadstart);
            this.$player.addListener("loadedmetadata", this.onLoadedmetadata);
            this.$player.addListener("duration", this.onDuration);
            this.$player.addListener("play", this.onPlay);
            this.$player.addListener("waiting", this.onWaiting);
            this.$player.addListener("seeking", this.onSeeking);
            this.$player.addListener("seeked", this.onSeeked);
            this.$player.addListener("timeupdate", this.onTimeupdate);
            this.$player.addListener("ended", this.onEnded);
            this.$player.addListener("stalled", this.onStalled);
            this.$player.addListener("isClick", this.onIsClick);
            this.$player.addListener('dispose', this.onDispose);
            this.$player.addListener('showBigPlay', this.onShowBigPlay);
        },

        offEvent() {
            this.$player.removeListener("loadstart", this.onLoadstart);
            this.$player.removeListener("loadedmetadata", this.onLoadedmetadata);
            this.$player.removeListener("duration", this.onDuration);
            this.$player.removeListener("play", this.onPlay);
            this.$player.removeListener("waiting", this.onWaiting);
            this.$player.removeListener("seeking", this.onSeeking);
            this.$player.removeListener("seeked", this.onSeeked);
            this.$player.removeListener("timeupdate", this.onTimeupdate);
            this.$player.removeListener("ended", this.onEnded);
            this.$player.removeListener("stalled", this.onStalled);
            this.$player.removeListener("isClick", this.onIsClick);
            this.$player.removeListener('dispose', this.onDispose);
            this.$player.removeListener('showBigPlay', this.onShowBigPlay);
        },

        onLoadstart() {
            this.isLoaded = false;
        },

        onLoadedmetadata() {
            this.isLoaded = true;
            this.isPlay = false;
        },

        onDuration(duration) {
            this.duration = duration;
        },

        onPlay(isPlay) {
            this.isPlay = isPlay;

            if (this.isPlay) {
                this.isLoaded = true;
            }

            if (!this.isPlay && this.ctrlTimer) {
                clearTimeout(this.ctrlTimer);
                this.ctrlTimer = null;
            }
        },

        onWaiting() {
            this.isLoaded = false;
        },

        onSeeking() {
            this.isLoaded = false;
        },

        onSeeked() {
            this.isLoaded = true;
        },

        onTimeupdate(currTime, buffered, bufferedPercent) {
            this.isPlay = true;

            this.currTime = currTime;
            this.buffered = buffered;
            this.bufferedPercent = bufferedPercent;

            if (!this.touchData) {
                this.progress = currTime;
            }
        },

        onEnded() {
            this.isLoaded = true;
            this.isPlay = false;
        },

        onStalled() {
            this.isLoaded = true;
            this.isPlay = false;
        },

        onIsClick(val) {
            // 回放双击暂停
            if (!this.isLiveRoadshow) {
                let time = Date.now();
                let distance = time - this.clickTime;
                this.clickTime = time;
                if (distance < 300) {
                    this.handleCtrl(true);
                    return this.$player.dispatchEvent('setPlay', !this.isPlay);
                }
            }
            this.handleCtrl(val);
        },

        onDispose() {
            this.isLoaded = false;
            this.isPlay = false;
        },

        onShowBigPlay(val = true) {
            this.showBigPlay = val;
        },

        handleCtrl(showCtrl = false) {
            this.showCtrl = showCtrl || !this.showCtrl;

            clearTimeout(this.ctrlTimer);

            if (this.showCtrl) {
                this.ctrlTimer = setTimeout(() => {
                    if (this.touchData) { return }

                    this.showCtrl = false;
                    this.showCloseWinTips = false;
                }, 5 * 1000)
            }
        },

        handleStart() {
            if (!this.$player.videoSource) {
                return this.$player.dispatchEvent("joinError");
            }
            this.showBigPlay = false;

            // 渲染播放组件
            this.$player.dispatchEvent('startRender');
        },

        handleMute() {
            this.isMute = !this.isMute;
            this.handleCtrl(true);

            this.$player.dispatchEvent('setMuted', this.isMute);
        },

        handlePlay: debounce(function () {
            this.isPlay = !this.isPlay;
            this.$player.dispatchEvent('setPlay', this.isPlay);
        }, 500),

        handleWin() {
            this.$player.closeWin = "";
            this.showCloseWinTips && this.handleCloseWinTips();
        },

        handleCloseWinTips() {
            this.showCloseWinTips = false;
            localStorage.setItem("HIDE_CLOSE_WIN_TIPS", 1);
        },

        handleFullscreen(val) {
            this.$player.isFullscreen = val;
            this.$player.dispatchEvent('fullscreen', val);

            let appEl = document.getElementById('app');
            val ? appEl.classList.add('fullscreen') : appEl.classList.remove('fullscreen');

            // 使用iframe嵌入的方式需要通知
            if (this.$player.isPlayer && window.self !== window.parent) {
                window.parent.postMessage({
                    event: 'fullscreen',
                    value: val
                }, "*");
            }
        },

        handleSpeed(showSpeed) {
            this.showSpeed = showSpeed || !this.showSpeed;

            clearTimeout(this.speedTimer);
            this.showSpeed && (this.speedTimer = setTimeout(() => {
                this.showSpeed = false;
            }, 3 * 1000));
        },

        setSpeed(time) {
            this.progress += time;
            if (this.progress < 0) {
                this.progress = 0;
            } else if (this.progress > this.duration) {
                this.progress = this.duration;
            }
            this.$player.dispatchEvent('progress', this.progress);
        },

        handleRate() {
            this.showRate = true;
            this.handleCtrl();
        },

        setRate(item) {
            this.playRate = item.value;
            this.$player.dispatchEvent('setSpeed', item.value);
            this.$player.dispatchEvent('message', item.label + '倍速播放中')
        },

        handleMessage() {
            this.$player.messageNewCount = 0;
            this.$player.showMessage = !this.$player.showMessage;
        },

        touchDragStart() {
            const touch = event.touches ? event.touches[0] : event;
            const { width, height } = this.$refs.progress.getBoundingClientRect();
            const offsetLeft = this.$refs.drag.offsetLeft;

            this.touchData = this.$player.isFullscreen && !this.$player.isOrientation ? {
                clientX: touch.clientY,
                left: offsetLeft,
                width: height
            } : {
                clientX: touch.clientX,
                left: offsetLeft,
                width: width
            }
        },

        touchDragMove() {
            if (!this.touchData) { return }

            const touch = event.touches ? event.touches[0] : event;
            const clientX = this.$player.isFullscreen && !this.$player.isOrientation ? touch.clientY : touch.clientX;
            let distance = clientX - this.touchData.clientX + this.touchData.left;
            if (distance <= 0) {
                distance = 0;
            } else if (distance >= this.touchData.width) {
                distance = this.touchData.width;
            }

            this.progress = distance / this.touchData.width * this.duration;
        },

        touchDragEnd() {
            this.$player.dispatchEvent('progress', this.progress);
            this.touchData = null;

            this.handleCtrl(true);
        },

        touchProgress() {
            let rect = this.$refs.progress.getBoundingClientRect();
            let { top, left, width, height } = rect;

            if (this.$player.isFullscreen && !this.$player.isOrientation) {
                this.progress = (event.pageY - top) / height * this.duration;
            } else {
                this.progress = (event.pageX - left) / width * this.duration;
            }

            this.$player.dispatchEvent('progress', this.progress);
        },
    }
}
</script>
<style lang="less" scoped>
@import "./css/animation-rotate.less";

.c-player__ctrl {
    width: 0;
    height: 0;

    &.screen {
        .control-top {
            height: 0.68rem;
            padding: 0 0.24rem;

            .control-item {
                .title {
                    font-size: 0.16rem;
                }
                & + .control-item {
                    margin-left: 0.32rem;
                }
            }

            .control-top__right {
                .control-item {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                }
            }
        }
        .control-bottom {
            height: 0.92rem;
            padding: 0.2rem 0.12rem 0 0.12rem;

            .progress-box {
                position: absolute;
                top: 0.14rem;
                left: 0;
                width: 100%;
                height: 0.16rem;
                padding: 0 0.16rem;
            }

            .control-item {
                margin-right: 0.2rem;
            }

            .control-item.play {
                .icon-font {
                    font-size: 0.36rem;
                }
                .icon-svg {
                    width: 0.22rem;
                    height: 0.22rem;
                }
            }
        }

        .control-rate {
            align-items: center;
            justify-content: flex-end;

            .content {
                width: 250px;
                height: 100%;
                padding: 0.36rem 0.3rem;
                background-color: rgba(0, 0, 0, 0.8);
            }

            .rate-header {
                display: none;
            }

            .rate-list {
                flex-direction: column;
                padding: 0;
                height: 100%;
            }

            .rate-item {
                width: 100%;
                height: 0;
                flex: 1;
                color: #ffffff;
                background-color: rgba(255, 255, 255, 0.08);
                margin: 0;

                & + .rate-item {
                    margin-top: 0.15rem;
                }

                &.active {
                    color: #ff661a;
                    background: rgba(247, 106, 43, 0.1);
                    border: 0.25px solid rgba(255, 100, 20, 0.6);
                    border-radius: 2px;
                }
            }
        }
    }
}

.btn-play__big {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 9999;

    .icon {
        width: 0.48rem;
        height: 0.48rem;
        background-color: rgba(0, 0, 0, 0.4);
        border-radius: 50%;
        color: #fff;
        cursor: pointer;
        font-size: 0.32rem;
        line-height: 0.48rem;
        text-align: center;
        .icon-font {
            font-size: 0.32rem;
        }
    }

    .desc {
        color: #fff;
        font-size: 0.14rem;
        line-height: 0.2rem;
        margin-top: 0.12rem;
    }
}

.video-fade-enter-active,
.video-fade-leave-active {
    transition: opacity 0.8s;
}

.video-fade-enter,
.video-fade-leave-to

/* .fade-leave-active 在低于版本 2.1.8 中 */ {
    opacity: 0;
}

.control-top {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0.44rem;
    user-select: none;
    padding: 0 0.16rem;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.5) 0%,
        rgba(0, 0, 0, 0) 100%
    );
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1000;
}

.control-top__left {
    display: flex;
    width: 0;
    flex: 1;
}

.control-top__right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.control-bottom {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 0.52rem;
    padding: 0 0.16rem;
    user-select: none;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.5) 100%
    );
    display: flex;
    align-items: center;
    pointer-events: none;
    z-index: 1000;
}

.control-bottom__left {
    flex: 1;
    display: flex;
    align-items: center;
    margin-right: 0.16rem;
}

.control-item {
    color: #fff;
    text-align: center;
    min-width: 0.24rem;
    position: relative;
    pointer-events: auto;

    & + .control-item {
        margin-left: 0.2rem;
    }

    &.active {
        color: #ff661a;
    }

    &.play {
        margin-right: 0.08rem;
    }

    &.back {
        display: flex;
        width: 100%;
        text-align: left;

        .icon-font {
            font-size: 0.16rem;
            margin-left: 0.1rem;
            margin-right: 0.12rem;
        }

        > span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
            flex: 1;
            width: 0;
        }
    }

    .title {
        text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.16);
    }

    .txt {
        font-size: 0.12rem;
        margin-top: 0.02rem;
    }

    .icon-font {
        font-size: 0.24rem;
    }

    .icon-img {
        width: 0.24rem;
        height: 0.24rem;
    }

    .icon-svg {
        width: 0.17rem;
        height: 0.17rem;
        .animation-rotate();
    }

    .close-win-tips {
        position: absolute;
        right: -0.35rem;
        top: -0.42rem;
        border-radius: 0.02rem;
        background-color: rgba(0, 0, 0, 0.7);
        color: #ffffff;
        font-size: 0.12rem;
        display: flex;
        align-items: center;
        height: 0.32rem;

        &::after {
            content: "";
            position: absolute;
            bottom: -8px;
            right: 0.4rem;
            border-top: 8px solid rgba(0, 0, 0, 0.7);
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            width: 0;
            height: 0;
        }

        > span {
            padding: 0 0.12rem;
            word-break: keep-all;
            white-space: nowrap;
            position: relative;

            &::after {
                content: "";
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
                height: 0.12rem;
                width: 1px;
                background-color: rgba(255, 255, 255, 0.3);
            }
        }

        > i.icon-font {
            width: 0.32rem;
            height: 0.32rem;
            text-align: center;
            line-height: 0.32rem;
            font-size: 0.14rem;
            cursor: pointer;
        }
    }

    .message-news {
        position: absolute;
        top: -0.06rem;
        right: -0.08rem;
        width: 0.22rem;
        height: 0.16rem;
        font-size: 0.1rem;
        background: #fe4132;
        border-radius: 0.24rem;
        transform: scale(0.8);
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.control-desc {
    color: #ffffff;
    font-size: 0.12rem;
}

.progress-box {
    height: 100%;
    flex: 1;
    display: flex;
    align-items: center;

    .progress-bar {
        position: relative;
        flex: 1;
        margin: 0 0.1rem;
        pointer-events: auto;

        .progress {
            position: absolute;
            top: -0.01rem;
            left: 0;
            height: 0.02rem;
            border-radius: 1px;
        }

        .progress-mark__bar {
            position: absolute;
            top: 0;
            width: 100%;
            left: 0;
            height: 0.02rem;
            pointer-events: none;
            .mark-item {
                position: absolute;
                top: -0.01rem;
                width: 0.015rem;
                height: 0.015rem;
                background: #ff661a;
            }
        }

        .total {
            width: 100%;
            background-color: rgba(255, 255, 255, 0.5);
        }

        .buffered {
            width: 0;
            background-color: rgba(255, 255, 255, 0.5);
        }

        .loaded {
            width: 20%;
            background-color: #f76a2b;
        }

        .drag {
            width: 0.3rem;
            height: 0.2rem;
            top: 0;
            transform: translate(-50%, -50%);
            z-index: 1;

            &:before {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 0.08rem;
                height: 0.1rem;
                border-radius: 2px;
                background-color: #ffffff;
            }
        }
    }

    .currtime,
    .duration {
        font-size: 0.1rem;
        color: #ffffff;
        text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.16);
    }
}

.control-progress {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 0.02rem;
    z-index: 1000;

    .progress {
        position: absolute;
        top: 0;
        left: 0;
        height: 0.02rem;
        border-radius: 1px;
    }

    .total {
        width: 100%;
        background-color: rgba(255, 255, 255, 0.5);
    }

    .buffered {
        width: 0;
        background-color: rgba(255, 255, 255, 0.5);
    }

    .loaded {
        width: 20%;
        background-color: #f76a2b;
    }

    .progress-mark__bar {
        position: absolute;
        top: 0.01rem;
        width: 100%;
        left: 0;
        height: 0.02rem;
        pointer-events: none;
        .mark-item {
            position: absolute;
            top: -0.01rem;
            width: 0.015rem;
            height: 0.015rem;
            background: #ff661a;
        }
    }
}

.control-speed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);

    .speed-item {
        font-size: 0.48rem;
        color: #ffffff;

        & + .speed-item {
            margin-left: 0.64rem;
        }
        .icon-font {
            font-size: 0.32rem;
        }
    }
}

.control-subtitle {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.5);
    .content {
        background-color: #ffffff;
        margin-bottom: 0.3rem;
        border-radius: 0.08rem;
    }
    .subtitle-header {
        position: relative;
        width: 100%;
        padding: 0.2rem 0.16rem 0.12rem;
        font-size: 0.15rem;
        font-weight: 500;
        line-height: 0.22rem;
        color: #4a4a4a;
        &:before {
            width: 0.54rem;
            height: 0.04rem;
            content: "";
            position: absolute;
            top: 0.08rem;
            left: 50%;
            transform: translateX(-50%);
            border-radius: 2px;
            background: #dddee0;
        }
    }
    .subtitle-list {
        padding: 0 0.12rem;
        display: flex;
        flex-direction: column;
        .subtitle-item {
            width: 3.23rem;
            padding: 0.12rem;
            font-size: 0.15rem;
            line-height: 0.22rem;
            color: #12121a;
            display: flex;
            align-items: center;
            .selected-icon {
                margin-right: 0.12rem;
                width: 0.16rem;
                height: 0.16rem;
                display: flex;
                align-items: center;
                color: #ff661a;
            }
        }
    }
}

.control-rate {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.5);

    .content {
        height: 2.18rem;
        background-color: #ffffff;
    }

    .rate-header {
        height: 0.54rem;
        padding: 0.2rem 0.12rem 0;
        display: flex;
        justify-content: space-between;
    }

    .rate-list {
        display: flex;
        flex-wrap: wrap;
        padding-left: 0.04rem;
    }

    .rate-item {
        width: 1.01rem;
        height: 0.38rem;
        font-size: 0.15rem;
        color: #12121a;
        background: #f5f5f5;
        margin-left: 0.16rem;
        margin-top: 0.24rem;
        display: flex;
        align-items: center;
        justify-content: center;

        &.active {
            color: #ff661a;
            background: rgba(247, 106, 43, 0.1);
            border: 0.25px solid rgba(255, 100, 20, 0.6);
            border-radius: 2px;
        }
    }
}

.control-mode {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.5);

    .content {
        width: 2.88rem;
        height: 1.32rem;
        border-radius: 0.04rem;
        background-color: #ffffff;
        padding: 0.14rem;
    }

    .mode-item {
        height: 0.48rem;
        background: #f9f9f9;
        border-radius: 2px;
        display: flex;
        align-items: center;

        &.active {
            background: rgba(255, 102, 26, 0.1);
        }

        & + .mode-item {
            margin-top: 0.08rem;
        }

        .icon-svg {
            width: 0.2rem;
            height: 0.2rem;
            margin: 0 0.14rem;
        }

        > span {
            color: #12121a;
            font-size: 0.14rem;
        }

        .desc {
            color: #8e8d8c;
            margin-left: 0.08rem;
        }
    }
}
</style>
