import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import Vant from "vant";
import "./axios/index.js";
// import "vant/lib/index.css";
import "./style/index.less";
import "./assets/scripts/mobileAdaptive";
import gc from "./components/global";
import Message from "./components/message";
import sensors from '@/utils/sensors.js'

Vue.prototype.$sensors = sensors
Vue.prototype.$trackPageView = (options)=>{
  try{
    console.log('trackpageview start', options)
    sensors.track('PageView',{
      ...options
    }) 
  }catch(err){
    console.log('trackPageview err', err)
  }
}
Vue.prototype.$trackClick = (options)=>{
  try{
    sensors.track('PageClick',{
      ...options
    })
  }catch(err){}
}

Vue.use(Message);
// Vue.use(Vant);
Vue.config.productionTip = false;
// 注册全局组件
Vue.use(gc);
new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
