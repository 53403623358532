window.$js = window.$js || {};
export function onCallNative(module, method, params) {
    var PROCOTOL = 'guosen';
    //console.log(param);
    //console.log(callback);
    var l = "";
    var isMobile = clientType();
    var delIframe = function (id) {
        var tmp = document.getElementById(id);
        if (tmp && tmp.remove) {
            tmp.remove();
        } else if (tmp) {
            document.body.removeChild(tmp);
        }
    };
    if (arguments.length == 3) {
        l = PROCOTOL + '://' + module + '/' + method + '/?params=' + JSON.stringify(params);
    } else if (arguments.length > 3) {
        l = PROCOTOL + '://' + module + '/' + method + '/?params=' + JSON.stringify(params);
        l = arguments[3] == "" ? l : l + '&callback=' + arguments[3];
    }
    if (isMobile == 'android') {
        window.prompt(l);
    } else {
        var iframeid = module + method;
        var iframe = document.createElement("iframe");
        iframe.id = iframeid;
        iframe.src = l;
        iframe.style.display = "none";
        document.body.appendChild(iframe);
        setTimeout(function () {
            delIframe(iframeid);
        }, 100);
    }

    if (!(isMobile == 'ios' || isMobile == 'android')) {
        arguments[4] = arguments[4] || "{}";
        onCallJs('{"action":"' + arguments[3] + '","param":' + arguments[4] + '}');
    }
}
function onCallJs(nativeParam) {
    var encode_flag = arguments[1];
    if (!nativeParam || nativeParam == null || nativeParam == "undefined" || nativeParam == "") {
        return;
    }
    //校验参数数据
    if (encode_flag && encode_flag == "1") {
        nativeParam = decodeURIComponent(nativeParam);
    }
    nativeParam = nativeParam.replace(/\r\n|\\r\\n|\\r|\\n/g, "<br/>");
    nativeParam = nativeParam.replace(/\s/gm, "&nbsp;");
    nativeParam = nativeParam.replace(/\\/ig, '');
    var _nativeParam = null;
    try {
        // alert(nativeParam.param);
        //nativeParam.action : 业务名称, nativeParam.param : 业务参数
        _nativeParam = typeof nativeParam == "object" ? nativeParam : JSON.parse(nativeParam);
        if (window.$js) {
            if (typeof $js[_nativeParam.action] == "function") {
                $js[_nativeParam.action](_nativeParam.param);
            }
        } else {
            window[_nativeParam.action](_nativeParam.param);
        }
    } catch (err) {

    }
}
function clientType() {
    var u = navigator.userAgent;
    var app_type;
    //return false;
    if (u.indexOf('Android') > -1) {
        app_type = 'android'; //android
    } else if (!!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
        app_type = 'ios'; //ios
    }
    return app_type;
}
