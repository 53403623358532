<template>
    <div class="summary">
        <div v-if="loading" class="summary-loading">
            <Lottie :data="gifJson"></Lottie>
            <div :class="[
                'summary-loading-progress',
                'progress-start',
                { 'progress-end': animationEnd },
            ]"></div>
        </div>
        <template v-else>
            <div class="summary-main">
                <div class="title">{{ title }}</div>
                <div class="tags">
                    <div class="tag">{{ industryValue }}</div>
                    <div v-if="stockName" class="tag">{{ stockName }}</div>
                </div>
                <div class="subs">
                    <span class="subs-name">{{ employee }} | {{ startTime | timeFilter }}</span>
                    <span class="subs-status" v-if="tag === 1">限时回放 </span>
                </div>
                <img src="@img/banner.png" class="banner" />
                <div class="banner"></div>
                <div class="content">
                    <div class="introduce_title" v-html="sumup"></div>
                    <!-- <div class="indent">
                        会议主要讨论了咖啡行业的产业链上游的包材环节。主讲人李永健分析了包材在咖啡产业链中的重要性，并介绍了句材行业的其木情况国内咖啡茶饮句装材料质是提升嘉联等优秀企业供应这部分产能。未来咖啡茶饮行业仍具成长性，嘉联在国内市场的业务有望继续增长
                    </div>
                    <div>值得关注</div>
                    <div>1.咖啡行业产业链上游的包材环节，包括杯子、盖子吸管和手机袋等</div>
                    <div>
                        2.嘉联作为优秀的供应商，长期供应海外市场，近年也开始分散产能和精力给国内市场3.国内咖啡和奶茶包装材料需求增加，主要供给以嘉联为首的优秀企业在做4.嘉联为喜茶和三顿半提供高端联名杯子，中小厂子可能难以做到。
                    </div> -->
                </div>
            </div>
            <div class="disclaimer">
                <p>【免责声明】本报告摘要由国信证券股份有限公司制作，报告摘要版权归国信证券股份有限公司(以下简称“我公司”)所有。本摘要仅供我公司客户使用，本公司不会因接收人收到本报告摘要而视其为客户。未经书面许可，任何机构和个人不得以任何形式使用、复制、转发或传播。任何有关本报告的摘要或节选都不代表本报告正式完整的观点，一切须以我公司向客户发布的本报告完整版本为准。
                </p>

            </div>
        </template>
    </div>
</template>
<script>
import { onCallNative } from '@/utils/communication';
import Lottie from "@comp/lottie/index.vue";
import gifJson from "./loading.json";
import dayjs from 'dayjs';

console.log(process.env.NODE_ENV);
export default {
    name: "SummaryRouter",
    components: {
        Lottie,
    },
    data() {
        return {
            animationEnd: false,
            loading: true,
            gifJson,
            title: '国信传媒|传媒行业中报业绩回顾暨9月投资策略',
            tag: 1, // 1 限时  2不限时
            employee: '张三',
            startTime: '2023-10-31 10:00',
            industryValue: '',
            stockName: '',
            statusValue: '',
            sumup: '',
        };
    },
    created() {
        onCallNative('WebContainer', 'titleItem', { 'title': '会议总结', 'bgColor': 'ffffff', 'titleColor': '212634' });
        this.init();
        window.scrollTo(0, 0)
        const dataes = JSON.parse(localStorage.getItem('myData'));

        if (dataes) {
            try {
                this.dataes = dataes;
                this.title = this.dataes.title || '';
                this.statusValue = this.dataes.statusValue || '';
                this.startTime = this.dataes.startTime || '';
                if (this.dataes.staffInfoDtos.length > 0) {
                    this.employee = this.dataes.staffInfoDtos[0].name || '';
                    this.industryValue = this.dataes.staffInfoDtos[0].industryValue || '';
                } else {
                    this.employee = '';
                    this.industryValue = '';
                }
                
                if (this.dataes.srsRoadshowStockRels.length > 0) {
                    this.stockName = this.dataes.srsRoadshowStockRels[0].stockName || '';
                } else {
                    this.stockName = ''
                }
                this.sumup = this.dataes.srsRoadshowSummary && +this.dataes.srsRoadshowSummary.isPublish === 1
                    ? this.dataes.srsRoadshowSummary.summary
                    : '';


            } catch (e) {
                console.error("Failed to parse dataes:", e);
            }
        }
    },
    filters: {
        timeFilter(time) {
            if (!time) return '';
            let formatText = 'YYYY-MM-DD HH:mm';
            return dayjs(time).format(formatText);
        }
    },
    mounted() {
        window.scrollTo(0, 0)
        this.$trackPageView({
            requirement_id: 1391,
            track_sign: "aInvestmentConsultant.b8484.pageview.PageView",
            content_title :this.title ||  '会议总结'
        });
    },
    methods: {
        init() {
            const time = Math.floor(Math.random() * 3000);
            console.log(time);
            setTimeout(() => {
                this.animationEnd = true;
                setTimeout(() => {
                    this.loading = false;
                }, 3000);
            }, time);
        },
    },
};
</script>
<style lang="less" scoped>
.summary {
    width: 100%;
    // height: 100%;
    background: #F4F5F6;

    &-loading {
        position: relative;

        &-progress {
            position: absolute;
            left: 0.84rem;
            top: 4.03rem;
            height: 0.07rem;
            background-color: #4d83e7;
            border-radius: 0.09rem;

            &.progress-start {
                animation-name: animation-start;
                animation-duration: 2s;
                animation-fill-mode: forwards;
                animation-timing-function: cubic-bezier(0.07, 0.86, 0.53, 0.92);
            }

            &.progress-end {
                animation-name: animation-end;
                animation-duration: 1s;
                animation-fill-mode: forwards;
                animation-timing-function: linear;
            }
        }
    }

    &-main {
        background: #fff;
        padding: 0.15rem 0.15rem 0.13rem 0.15rem;
    }

    .subs {
        display: flex;
        align-items: center;
        font-size: .11rem;
        line-height: 0.12rem;
        color: #677288;
        margin-bottom: 0.21rem;

        &-name {
            margin-right: .07rem;
        }

        &-status {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 0.48rem;
            height: 0.15rem;
            font-size: 0.1rem;
            border: 0.05px solid #939AAA;
            border-radius: 0.02rem;
        }
    }

    .banner {
        display: block;
        width: 100%;
        margin-bottom: 0.12rem;
    }

    .content {
        font-size: 0.15rem;
        color: #272736;
        text-align: justify;
        line-height: 0.31rem;
        font-weight: 400;

        .indent {
            text-indent: 2em;
        }
    }
}

@keyframes animation-start {
    0% {
        width: 0;
    }

    100% {
        width: calc(2.07 * 0.95rem);
    }
}

@keyframes animation-end {
    0% {
        width: calc(2.07 * 0.95rem);
    }

    100% {
        width: 2.07rem;
    }
}


.title {
    font-size: .2rem;
    color: #212634;
    line-height: .28rem;
    font-weight: 500;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: 0.1rem;
}

.tags {
    margin-bottom: 0.1rem;

    .tag {
        display: inline-block;
        font-size: .1rem;
        color: #447EF2;
        font-weight: 400;
        background: #EFF4FF;
        border-radius: 0.02rem;
        // padding: 0.015rem 0.04rem;
        // margin: 0 0.04rem 0.04rem 0;

        padding: 0.035rem 0.05rem;
        margin: 0 0.04rem 0.0rem 0;
    }
}

.disclaimer {
    padding: .17rem .15rem;
    font-size: .12rem;
    color: #939AAA;
    font-weight: 400;
    line-height: .2rem;
}
</style>
