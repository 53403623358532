import moment from 'moment' 
/** 
 * 神策数据埋点 主站神策数据初始化后挂载到window对象 
 * @param {*} eventName 自定义事件名称
 *  @param {*} data 数据
 *  @param {*} callback
 * @param {*} alwaysCallback 
 */ 
export default function sensorsTrack ( eventName, data = {}, callback = () => {}, alwaysCallback = true ) { 
    try { 
        const prefix = process.env.NODE_ENV === 'production' ? '' : process.env.NODE_ENV + '_' 
        if (!eventName) { 
            callback() 
            return 
        } 
        const userStore = this.$route.query.id
        let commonPonintsParams = {} 
        if (userStore) { 
            const { id } = this.$route.query.id 
        //     // 通用参数 为了保证获取参数的时机不过于提前，公共参数不提取到函数外
            commonPonintsParams = { }
        } 
        const paramTemp = Object.assign({}, commonPonintsParams, data) 
        if (window.sensors) { 
            eventName = prefix + eventName 
            window.sensors.track(eventName, paramTemp, () => { 
                callback() 
            }) 
        } else if (alwaysCallback) { 
            callback() 
        } 
    } catch (e) { 
        console.log(e) 
        callback() 
    } 
}