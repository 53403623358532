<template>
    <div class="c-player__bg" :style="{ backgroundImage: 'url(' + backImageUrl + ')' }">
    </div>
</template>
<script>
import { mapState } from 'vuex';
export default {
    data() {
        return { }
    },
    computed: {
        ...mapState('live', ['roadshowData']),


        backImageUrl() {
            return this.roadshowData.logowall169 || this.roadshowData.logo || ''
        },
    }
}
</script>
<style lang="less" scoped>
.c-player__bg {
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
}

.c-status {
    background: rgba(31, 33, 36, 0.6);
    border-radius: 4px;
    position: absolute;
    top: 0.1rem;
    left: 0.12rem;
    z-index: 999;
    padding: 0.04rem 0.08rem 0.04rem 0.16rem;
    box-sizing: border-box;
    font-size: 0.12rem;

    .icon {
        display: inline-block;
        width: 0.06rem;
        height: 0.06rem;
        border-radius: 0.06rem;
        background-color: #ef5d52;
        opacity: 1;
        position: absolute;
        left: 0.06rem;
        top: 0.08rem;

        &.not-live {
            background-color: #999999;
        }
    }

    .text {
        font-family: PingFangSC-Regular;
        color: #ffffff;
        display: inline-block;
    }

    .error {
        color: #ff661a;
    }
}

.c-watermark {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background-size: 20vh;
    pointer-events: none;
}

.c-living-member {
    position: absolute;
    bottom: 0.1rem;
    right: 0.11rem;
    z-index: 99999;
    padding: 0.08rem 0.3rem;
    box-sizing: border-box;
    background-size: contain;
}

.c-message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 0.15rem;
    color: #ffffff;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 4px;
    padding: 0.12rem;
    z-index: 1000;
}

.c-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1.11rem;
    height: 0.85rem;
    font-size: 0.15rem;
    color: #ffffff;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.8);
    border-radius: 0.04rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
        width: 0.32rem;
        height: 0.32rem;
        margin-bottom: 0.08rem;
        animation: rotate 2s linear infinite;
    }
}

@keyframes rotate {
    0% {
        transform: rotate(360deg);
    }
    100% {
        transform: rotate(0deg);
    }
}
</style>