import Vue from 'vue';

export const PlayerMode = {
    // 高延时
    Live: 'live',
    // 低延时
    WebRtc: 'rtc'
}

// 直播类型
export const LiveMode = {
    // 音频
    Audio: 0,
    // 视频
    Video: 1,

    0: "Audio",
    1: "Video"
}

// 用户身份
export const RoleType = {
    // 普通用户
    User: 0,
    // 主讲
    Speaker: 1,
    // 嘉宾
    Guest: 2,

    0: '',
    1: '主讲',
    2: '嘉宾',
}

// 频道内身份
export const ClientRole = {
    // 观众
    Audience: "audience",
    // 主播
    Host: 'host',
}

// 低延时 媒体类型
export const MediaType = {
    Desktop: 'desktop',
    Video: 'video',
    Audio: 'audio',
    Pdf: 'pdf',
}

// 举手状态
export const RaiseStatus = {
    // 未举手
    None: 'none',
    // 已举手，等待发言
    Wait: 'wait',
    // 正在发言
    Speak: 'speak'
}

// 发言状态
export const SpeakStatus = {
    // 等待发言
    Wait: 0,
    // 正在发言
    Speak: 1,
}

// 静音状态
export const MuteStatus = {
    // 非静音
    UnMuted: 0,
    // 静音
    Muted: 1
}

// 网络端提问
export const InteractiveMode = {
    // 文字 + 提问
    WordAndAsk: 0,
    // 仅提问(需审核)
    OnlyAsk: 1,
    // 禁止
    Disable: 2,

    0: 'WordAndAsk',
    1: 'OnlyAsk',
    2: 'Disable'
}

// 字幕
export const Subtitle = {
    Hidden: 'hide',
    Visible: 'visible',
    CN: 'cn',
    EN: 'en'
}

// 声源语音
export const Language = {
    Auto: 'auto',
    CN: 'cn',
    EN: 'en'
}

// ppt 翻页限制
export const WatchPptMod = {
    All: 1, // 观众可观看所有页面 默认值
    ViewPast: 2,   // 观众仅能观看已演示的页面
    ViewOnly: 3  // 观众仅能观看当前演示的页面
}

// 路演状态
export const RoadshowLiveStatus = {
    Preview: 0, // 预告
    Live: 1,   // 直播
    Empty: 2,  // 空白
    Record: 3  // 回放）

}

// 布局方式
export const LiveLayout = {
    // 演示布局 默认
    Demo: 0,
    // 等分布局
    Bisection: 1,
}


class Player {

    constructor() {
        // 播放器
        this.isPlayer = false;
        // 直播状态 true = 直播中
        this.isLive = false;
        // 直播类型 true = 预告 false = 回放
        this.isLiveRoadshow = false;
        // 播放地址
        this.videoSource = "";
        // 全屏
        this.isFullscreen = false;
        // 旋转
        this.isOrientation = window.orientation == 90 || window.orientation == -90;
        // 显示字幕
        this.showSubtitle = false;
        // 显示全部字幕
        this.showAllSubtitle = false;
        // 字幕显示
        this.subtitleValue = Subtitle.Hidden;
        // 字幕时间戳
        this.subtitleTimestamp = 0;
        // 全屏状态
        this.isFullscreen = false
        // 直播模式
        this.mode = '';
        // 主屏幕显示媒体类型 desktop = 桌面共享 video = 视频画面 pdf = 演示文稿
        this.screenType = "";
        // 显示/隐藏小窗
        this.closeWin = "";
        // 全屏模式是否显示互动消息
        this.showMessage = false;
        // 举手发言弹框
        this.showRaiseHand = false;
        // 新消息
        this.messageNewCount = 0;
    }

    eventHandler() {}

    addListener(eventName, handler) {
        if (!(eventName in EventName)) {
            return console.error('addListener warning: eventName "' + eventName + '" is undefined');
        }

        if (!this.eventHandler[eventName]) {
            this.eventHandler[eventName] = [];
        }
        this.eventHandler[eventName].push(handler);
    }

    removeListener(eventName, handler = null) {
        if (!(eventName in EventName)) {
            return console.error('removeListener warning: eventName "' + eventName + '" is undefined');
        }

        if (handler) {
            let eventList = this.eventHandler[eventName];
            let index = eventList ? eventList.findIndex(event => event === handler) : -1;
            index >= 0 && eventList.splice(index, 1);
        } else {
            delete this.eventHandler[eventName];
        }
    }

    dispatchEvent(eventName, ...params) {
        if (!(eventName in this.eventHandler)) {
            return {
                code: -1,
                message: '未注册该事件'
            }
        }
        this.eventHandler[eventName].forEach(handler => {
            handler && handler(...params);
        })
    }
}

export const player = new Player();

export const playerMixin = {
    data() {
        return {
            player
        }
    }
}

export default {
    register() {
        Object.assign(player, new Player());

        Vue.prototype.$player = player;
    },
    dispose() {
        player.eventHandler = {};
    }
}

// 播放器事件
const PlayerEventName = {
    // 播放状态改变
    play(isPlay = false) { },
    // 开始加载
    loadstart() { },
    // 加载完成
    loadedmetadata() { },
    // 可以播放
    canplay() { },
    // 播放时长
    duration() { },
    // 播放进度
    timeupdate() { },
    // 播放卡顿
    waiting() { },
    // 跳转
    seeking() { },
    // 跳转完成
    seeked() { },
    // 播放完成
    ended() { },
    // 播放失败
    stalled() { },
    // 控制播放
    setPlay() { },
    // 播放失败
    error() { },
    // 销毁
    dispose() { },
    // 实时字幕
    setSubtitleSoon() { },
    // 全部字幕
    setSubtitle() { },
    // 路演概要变更
    setAiChapterChange() { },
}

// 控制栏相关事件
const CtrlEventName = {
    // 控制栏显示状态
    showCtrl() { },
    // 设置倍速
    setSpeed() { },
    // 设置直播模式
    setMode() { },
    // 直播模式改变
    onMode() { },
    // 直播线路切换
    onCdnLine() { },
    // 开始渲染
    startRender() { },
    // 重新载入
    refresh() { },
    // 拖放进度
    progress() { },
    // 静音
    setMuted() { },
    // 全屏
    fullscreen() { },
    // 旋转
    orientation() { },
    // 关闭小窗
    showPdf() { },
    // 显示大播放按钮
    showBigPlay() { },
    // 鉴权失败
    joinError() { },
}

const EventName = {
    ...PlayerEventName,
    ...CtrlEventName,

    // 窗口变化
    resize() { },
    // 提示消息
    message() { },
    // 点击播放器
    isClick() { },
    // 播放宣传片
    setTrailer() { },
    // 网络质量
    networkQuantity() { },
    // 连接状态
    connectState() { },
    // 播放白噪音
    playWhiteNoise() { },
    // 初始化屏幕窗口
    initScreen() { },
    // 处理自动播放失败
    setReplayAudio() { },
}