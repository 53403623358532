import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import Qs from "qs";

import store from "../store";
// import api from "./interface";
import {
	SRS_URL
} from './host';

const vue = new Vue();

axios.defaults.timeout = 30000;
axios.defaults.retry = 4;
axios.defaults.retryDelay = 1000;
axios.defaults.retryCount = 0;

function Axios() {
	// 弹窗标识、控制只弹出一个对话框
	this.showMessage = false;

	// 存储请求队列
	this.reqQueue = [];

	// 刷新Token标识
	this.isRefreshToken = false;
}

Axios.prototype.request = function (config) {
    return axios
        .request(config)
        .then(res => this.success(res))
        .catch(err => this.error(err, config));
};
Axios.prototype.setReqQueue = function (config) {
    return new Promise((resolve, reject) => {
        this.reqQueue.push({
            config,
            resolve,
            reject
        });
    })
}
Axios.prototype.post = function (url = "", body = {}, options = {}) {
	//初始化请求配置参数
	let axiosConfig = new AxiosConfig(url, 'post', options);
	axiosConfig.instance();
	axiosConfig.data = body;

	switch (axiosConfig.bodyType) {
		case "form":
			// 固定请求参数放在 body
			Object.assign(axiosConfig.data, axiosConfig.initUrl());

			axiosConfig.transformRequest = [
				data => {
					return Qs.stringify(data);
				}
			];
			break;
		case "image":
		case "file":
			axiosConfig.headers["Content-Type"] = "multipart/form-data";
			axiosConfig.timeout = 1800000; //超时时间为半小时

			var formData = new FormData();
			Object.keys(body).map(index => {
				formData.append(index, body[index]);
			});
			axiosConfig.data = formData;
			break;
		default:
			// 固定请求参数放在 headers
			Object.assign(axiosConfig.headers, axiosConfig.initUrl());
	}

	return this.request(axiosConfig);
};

Axios.prototype.get = function (url = "", body = {}, options = {}) {
	//初始化请求配置参数
	let axiosConfig = new AxiosConfig(url, 'get', options);
	axiosConfig.instance();

	// 固定请求参数放在 body
	Object.assign(axiosConfig.params, axiosConfig.initUrl(), body);

	return this.request(axiosConfig);
};

Axios.prototype.success = function (res) {
	const code = ~~res.data.errorcode || ~~res.data.code;

	// code === 0 为成功，直接返回，其余转错误处理
	return code ? Promise.reject(res) : res.data;
};

Axios.prototype.error = function (err, config) {
	// 默认请求超时
	let response = {
		errorcode: 500,
		errordesc: "网络较差，请稍后重试"
	};

	// 对返回值赋值
	Object.assign(response, err.data);

	// 处理errorcode 和 code
	response.errorcode = response.code || response.errorcode;
	response.errordesc = response.msg || response.errordesc;

	// 返回http状态码401
	if (err.response && (err.response.status === 401 || err.response.status === 403)) {
		// response = {
		// 	errorcode: 201,
		// 	errordesc: '您的登录信息过期,重新登录'
		// }
        this.refreshToken();
        return this.setReqQueue(config);
	}

	// 返回请求500错误
	if (err.response && err.response.status == 500) {
		response = {
			errorcode: 500,
			errordesc: "连接服务器失败，请稍后重试"
		};
	}

	const code = ~~response.errorcode;

	if (!err.config.hideMessage && !this.showMessage) {
		this.showMessage = true;

		switch (code) {
			case 201:
				this.refreshToken();
                return this.setReqQueue(config);
			default:
				vue.$showToast(response.errordesc).then(() => {
					this.showMessage = false;
				})
		}
	}
	return Promise.reject(response);
};

Axios.prototype.refreshToken = function () {
    if(this.isRefreshToken) return;
    const userAuth = store.getters.userAuth;
	this.isRefreshToken = true;

	// this.post(api.login.getAuth, {
	// 	grantType: 'refresh_token',
	// 	clientId: 'h5-mobile',
	// 	refreshToken: userAuth.refreshToken
	// }, {
	// 	requireAuth: false,
	// 	hideMessage: true
	// }).then(res => {
    //     console.log('refreshTokenRes', res);
	// 	this.setUserAuth(res.data);
	// }).catch(err => {
    //     console.log('refreshTokenerr', err);
    //     this.setUserAuth(null);
	// })
}

Axios.prototype.setUserAuth = function (userAuth) {
    console.log('this.reqQueue', this.reqQueue);
	store.commit('updateUserAuth', Object.assign({}, userAuth, {
		timestamp: Date.now()
	}));

	this.isRefreshToken = false;

	let authorization = userAuth ? userAuth.tokenType + ' ' + (userAuth.accessToken || userAuth.token) : '';

	this.reqQueue.forEach(item => {
		item.config.headers.Authorization = authorization;

		axios.request(item.config).then(res => this.success(res)).then(item.resolve).catch(err => this.error(err)).catch(item.reject);
	});

	this.reqQueue = [];
}


function AxiosConfig(url = "", method = 'post', options = {}) {
	// 发送请求的地址
	this.url = url;

	this.method = method;

	// 发送请求的数据
	this.data = {};
	this.params = {};

	// 被发送的自定义请求头
	this.headers = {};

	// 以下为自定义的一些参数

	// bodyType: String     请求参数数据类型 支持 json / form / file / image 默认json
	this.bodyType = 'json';

	// hideMessage: Boolean 请求失败是否显示错误信息
	this.hideMessage = false;

	// showModal: Boolean   未登录是否显示提示框
	this.showModal = false;

	// requireAuth: Boolean 接口请求是否需要登录权限
	this.requireAuth = true;

	Object.assign(this, options);
}

AxiosConfig.prototype.instance = function () {
	//默认设置token到请求头
	let userInfo = store.getters.userInfo;
	let userAuth = store.getters.userAuth;

	// 用户ID (非必填)
	this.headers.uid = userInfo ? userInfo.uid : "";
	// 操作系统平台 ios android web
	this.headers.os = "web";
	// os为web的扩展字段 pc端传值：pc , 手机端传值：mobile
	this.headers.c = "mobile";
	// os为web的扩展字段 web所处的环境 weixin qq comein minprogram
	this.headers.webenv = "comein";
	// 手机操作系统
	this.headers.s = "";
	// 浏览器
	this.headers.browse = window.navigator.appName;
	// user agent
	this.headers.ua = window.navigator.userAgent;
	// 外部展 示版本号(3.0.3)
	// this.headers.b = "3.7.3";
	// 用户渠道
	// this.headers.uc = vue.$bridge.uc;

	// 会话令牌 (非必填)
	this.headers.token = userInfo ? userInfo.token : '123';
    
	this.headers.Authorization = userAuth ? userAuth.tokenType + ' ' + (userAuth.accessToken || userAuth.token) : '123';

	// this.headers.realm = vue.$bridge.realms;

	/** 上述参数中非必选的参数，在能拿到信息的情况下都需要传入接口。 */

	this.headers["Content-Type"] = this.bodyType === 'form' ? 'application/x-www-form-urlencoded;charset=utf-8' : "application/json;charset=utf-8";
};

AxiosConfig.prototype.initUrl = function () {
	// this.url = this.url.replace('{realms}', vue.$bridge.realms);
	this.url = this.url.replace('{realms}', 'main');

	let arr = this.url.split("_");

	if (arr.length != 3) {
		if (this.url.indexOf('http') != 0) {
			this.url = SRS_URL + this.url;
		}
		return {};
	}

	// this.url = api.common.phpUrl;
	return {
		app: arr[0],
		mod: arr[1],
		act: arr[2]
	};
};

Vue.prototype.$axios = new Axios();

Vue.use(VueAxios, axios);