<template>
    <div class="c-player__pdf" id="pdfPlayer" @touchmove.prevent>
        <swiper
            class="pdf-swiper"
            ref="mySwiper"
            :options="swiperOptions"
            @touchStart="handleTouchStart"
            @touchMove="handleTouchMove"
        >
        </swiper>

        <!-- pdf加载中状态 -->
        <div class="pdf-progress" v-show="!bolRender">
            <div class="pdf-progress__wrap">
                <div
                    class="pdf-progress__loaded"
                    :style="{ width: pdfLoaded + '%' }"
                ></div>
            </div>
        </div>

        <!-- 页码 -->
        <div class="pdf-page">
            <span>{{ pageNum }} / {{ pageCount }}</span>
        </div>
    </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { swiper } from "vue-awesome-swiper";

export default {
    props: {
        allowTouchMove: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            pid: 0,
            pageList: [],
            pageCount: 0,
            pageNum: 1,
            bolRender: false,
            pdfLoaded: 0,

            renderCount: 10,
            swiperOptions: {
                allowTouchMove: this.allowTouchMove,
            },

            pdfSwiperOrigin: {
                startX: 0,
                startY: 0,
            },

            pdfTips: false,
        };
    },

    components: {
        swiper,
    },

    computed: {
        ...mapState("live", [
            "roadshowData",
            "isLiveRoadshow",
            "pdfData",
            "pdfList",
            "user",
        ]),
        swiper() {
            return this.$refs.mySwiper.swiper;
        },
    },

    watch: {
        pdfData(data, pre) {
            this.onPdfData(data, pre);
        }
    },

    mounted() {
        this.startRender();
        this.onEvent();
    },

    beforeDestroy() {
        clearInterval(this.interval);

        this.offEvent();
    },

    methods: {
        onEvent() {
            this.swiper.on(
                "slideChangeTransitionStart",
                this.slideChangeTransitionStart
            );
            this.swiper.on(
                "slideChangeTransitionEnd",
                this.slideChangeTransitionEnd
            );

            this.$player.addListener("resize", this.resizePage);
            this.$player.addListener("fullscreen", this.onFullscreen);
            this.$player.addListener("orientation", this.onOrientation);
        },

        offEvent() {
            this.$player.removeListener("resize", this.resizePage);
            this.$player.removeListener("fullscreen", this.onFullscreen);
            this.$player.removeListener("orientation", this.onOrientation);
        },

        onFullscreen() {
            this.resizePage();
        },

        onOrientation() {
            console.log("on orientation");
            setTimeout(() => {
                this.resizePage();
            }, 50);
        },

        onPdfData(data, pre) {
            if (this.pid != data.pid) {
                return this.startRender();
            }

            if (!this.bolRender) {
                return (this.pageNum = Number(data.page));
            }

            if (pre.page == this.pageNum || this.pdfData.syncppt == 1) {
                this.pageNum = Number(data.page);
                this.slideTo(this.pageNum);
            }
        },

        startProgress() {
            this.pdfLoaded = 0;
            let step = 0;

            clearInterval(this.interval);
            this.interval = setInterval(() => {
                step += 0.1;
                this.pdfLoaded += step;
                if (this.pdfLoaded >= 100) {
                    if (this.bolRender) {
                        this.pdfLoaded = 100;
                        clearInterval(this.interval);
                    } else {
                        this.pdfLoaded = 99;
                    }
                }
            }, 50);
        },

        startRender() {
            this.bolRender = false;

            this.pid = this.pdfData.pid;

            this.pageNum = Number(this.pdfData.page) || 1;

            this.swiper.removeAllSlides();

            this.startProgress();
            this.pageList = this.pdfList;
            this.pageCount = this.pageList.length;
            this.renderPage(this.pageNum);
            this.bolRender = true;
        },

        renderPage(pageNum) {
            pageNum = pageNum > 0 && pageNum <= this.pageCount ? pageNum : 1;

            let startPageNum =
                pageNum - this.renderCount < 1 ? 1 : pageNum - this.renderCount;
            let endPageNum =
                pageNum + this.renderCount > this.pageCount
                    ? this.pageCount
                    : pageNum + this.renderCount;

            for (let index = startPageNum; index <= endPageNum; index++) {
                this.swiper.appendSlide(this.createSlide(index));
            }

            this.swiper.slideTo(pageNum - startPageNum);
        },

        slideTo(pageNum) {
            if (!this.bolRender || pageNum < 0 || pageNum > this.pageCount) {
                return;
            }

            this.initSlides(pageNum);

            let index = Array.from(this.swiper.slides).findIndex(
                (slide) => Number(slide.getAttribute("data-number")) == pageNum
            );

            this.swiper.slideTo(index);
        },

        onSwiperPdf(origin) {
            this.swiper.allowSlidePrev = true;
            this.swiper.allowSlideNext = true;
        },

        handleTouchStart(ev) {
            this.pdfSwiperOrigin.startX = ev.touches[0].pageX;
            this.pdfSwiperOrigin.startY = ev.touches[0].pageY;
        },

        handleTouchMove(e) {
            const { startX, startY } = this.pdfSwiperOrigin;
            let moveEndX = e.changedTouches[0].pageX;
            let moveEndY = e.changedTouches[0].pageY;
            let X = moveEndX - startX;
            let Y = moveEndY - startY;
            if (Math.abs(X) > Math.abs(Y) && X > 0) {
                this.onSwiperPdf("prev");
            } else if (Math.abs(X) > Math.abs(Y) && X < 0) {
                this.onSwiperPdf("next");
            }
        },

        slideChangeTransitionEnd() {
            let pageNum = Number(
                this.swiper.slides[this.swiper.activeIndex].getAttribute(
                    "data-number"
                )
            );

            if (this.pageNum == pageNum) {
                return;
            }
            this.pageNum = pageNum;
            this.initSlides(pageNum);
        },

        initSlides(activeNumber) {
            let startPageNum =
                activeNumber - this.renderCount < 1
                    ? 1
                    : activeNumber - this.renderCount;
            let endPageNum =
                activeNumber + this.renderCount > this.pageCount
                    ? this.pageCount
                    : activeNumber + this.renderCount;

            let removeSlides = [];
            let currPages = [];

            // 移除
            Array.from(this.swiper.slides).forEach((slide, index) => {
                let number = Number(slide.getAttribute("data-number"));
                if (number < startPageNum || number > endPageNum) {
                    removeSlides.push(index);
                } else {
                    currPages.push(number);
                }
            });

            removeSlides.length && this.swiper.removeSlide(removeSlides);

            // 添加
            for (let pageNum = startPageNum; pageNum <= endPageNum; pageNum++) {
                let hasSlide = false;

                for (let index = 0; index < currPages.length; index++) {
                    if (pageNum == currPages[index]) {
                        hasSlide = true;
                        break;
                    }

                    if (pageNum < currPages[index]) {
                        hasSlide = true;
                        currPages.splice(index, 0, pageNum);
                        this.swiper.addSlide(index, this.createSlide(pageNum));
                        break;
                    }
                }

                if (!hasSlide) {
                    this.swiper.appendSlide(this.createSlide(pageNum));
                }
            }
        },

        createSlide(pageNum) {
            let slide = document.createElement("div");

            slide.className = "swiper-slide";
            slide.setAttribute("data-number", pageNum);
            slide.style =
                "display: flex; align-items: center; justify-content: center;";

            let image = document.createElement("img");
            image.className = "pdf-image";
            image.setAttribute("data-number", pageNum);
            image.style.opacity = 0;

            image.src = this.pageList[pageNum - 1].completepicurl;

            image.onload = () => {
                this.resetPage(image);
            };

            slide.appendChild(image);

            return slide;
        },

        resetPage(image) {
            let el = document.getElementById("pdfPlayer");
            if (!el) {
                return;
            }

            let isVertical =
                this.$player.isFullscreen && !this.$player.isOrientation;

            let width = el.clientWidth,
                height = el.clientHeight;

            image.style.width = "auto";
            image.style.height = "auto";
            image.style.opacity = 1;

            if (image.width / image.height <= width / height) {
                image.style.width =
                    (image.width / image.height) * height + "px";
                image.style.height = height + "px";
            } else {
                image.style.width = width + "px";
                image.style.height =
                    (image.height * width) / image.width + "px";
            }
            if (isVertical) {
                this.swiper.changeDirection("vertical");
                image.style.transform = "rotate(90deg) translate3d(0,0,0)";
            } else {
                this.swiper.changeDirection("horizontal");
                image.style.transform = "translate3d(0,0,0)";
            }
        },

        resizePage() {
            this.$nextTick(() => {
                this.swiper.update();

                Array.from(
                    document.getElementsByClassName("pdf-image")
                ).forEach((item) => {
                    this.resetPage(item);
                });
            });
        },
    },
};
</script>
<style lang="less" scoped>
@import "./css/swiper.min.css";
.c-player__pdf {
    width: 100%;
    height: 100%;
    background: #1e1e20;

    .pdf-swiper {
        width: 100%;
        height: 100%;
    }
}

.pdf-progress {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #1e1e20;
    z-index: 1001;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pdf-progress__wrap {
    position: relative;
    width: 1.3rem;
    height: 0.06rem;
    background: rgba(247, 106, 43, 0.1);
    border-radius: 4px;
    padding: 0 0.01rem;
    display: flex;
    align-items: center;

    &:before {
        top: 0;
        left: 0;
        content: "";
        width: 200%;
        height: 200%;
        position: absolute;
        border: 1px solid #f76a2b;
        transform-origin: 0 0;
        transform: scale(0.5, 0.5);
        box-sizing: border-box;
        border-radius: 4px;
    }
}

.pdf-progress__loaded {
    background: #f76a2b;
    height: 0.04rem;
    border-radius: 4px;
}

.pdf-page {
    position: absolute;
    bottom: 0.32rem;
    left: 50%;
    transform: translateX(-50%);
    height: 0.24rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-size: 0.12rem;
    border: 0.005rem solid rgba(0, 0, 0, 0.16);
    background-color: rgba(0, 0, 0, 0.36);
    padding: 0 0.12rem;
    border-radius: 0.12rem;
    z-index: 1001;
    min-width: 0.5rem;

    &.pageNotSync {
        color: #ff661a;
        border: 0.005rem solid rgba(255, 102, 26, 0.72);
        background-color: rgba(255, 102, 26, 0.32);
    }

    .pageLockBtn {
        margin-left: 0.04rem;
        width: 0.12rem;
        height: 0.12rem;
    }
}

.pdfSycTip {
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    z-index: 1001;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    &.pdfSycTip-fullscreen {
        width: 100vh;
        height: 100vw;
        transform: rotate(180deg);
        .pdfSycTip-main {
            top: 0.36rem;
            right: 50vw;
            transform: translateX(0);
            .pdfSycTip-content {
                transform: rotate(180deg);
            }
        }
    }
    .pdfSycTip-main {
        position: absolute;
        left: 50vw;
        transform: translateX(-50%);
        top: 1.7rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        flex-direction: column;
        .pdfSycTip-content {
            background: #ffffff;
            border-radius: 0.08rem;
            width: 2.9rem;
            .pdfSycTip-content__title {
                padding-top: 0.32rem;
                color: #12121a;
                text-align: center;
                font: 400 0.15rem/0.24rem "PingFang SC", sans-serif;
                position: relative;
                padding-bottom: 0.24rem;
            }
            .pdfSycTip-content__button {
                height: 0.44rem;
                line-height: 0.44rem;
                color: #ff661a;
                text-align: center;
                font: 500 0.15rem "PingFang SC", sans-serif;
            }
        }
    }
}
</style>
