/**
 * 提供全局方法
 * (c) 2017 进门web团队
 * **/

'use strict';

export const isApp = /ComeinFinance/i.test(navigator.userAgent.toLowerCase());
/* ios 支持 __wxjs_environment ， 安卓支持 UA */
export const isWxMiniProgram = /miniprogram/.test((window.__wxjs_environment || navigator.userAgent).toLowerCase());
export const isWx = /micromessenger/.test(window.navigator.userAgent.toLowerCase());

export const isWxWork = /wxwork/.test(window.navigator.userAgent.toLowerCase());

export const isIos = /(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent.toLowerCase());

// export const isIphoneX = isIos && (window.screen.height === 812 && window.screen.width === 375);
export const isIphoneX = isIos && ((window.screen.height === 812 && window.screen.width === 375)
	|| (window.screen.height === 896 && window.screen.width === 414));

export const isSafari = /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent);
/**
 * 防抖、 delay时间内只能触发一次、
 */
export const debounce = (fn, delay = 1000, start = true) => {
	let done = true;
	let timer = null;
	return function () {
		let _this = this;
		let args = arguments;

		// 首次执行
		if (start) {
			// 开关打开时，执行任务
			if (done) {
				done = false;
				fn.apply(_this, args);
			}
			// 清空上一次操作
			clearTimeout(timer);
			// 任务开关打开
			timer = setTimeout(function () {
				done = true;
			}, delay);
		} else {
			// 清空上一次操作
			clearTimeout(timer);
			// delay时间之后，任务执行
			timer = setTimeout(function () {
				fn.apply(_this, args);
			}, delay);
		}
	};
};
