<template>
    <div class="c-player__video">
        <video id="video"></video>
    </div>
</template>
<script>
import videojs from "video.js";
import 'videojs-contrib-hls'
import { mapMutations } from 'vuex';

export default {
    props: {
        source: {
            type: String,
            default: ""
        },
        rePlay: {
            type: Boolean,
            default: false,
        },
        autoplay: {
            type: Boolean,
            default: true
        },
        startTime: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            video: null,
        }
    },

    mounted() {
        this.initVideo();
        this.onEvent();
    },

    beforeDestroy() {
        this.$player.dispatchEvent('dispose');
        this.video && this.video.dispose();

        this.offEvent();
    },

    methods: {
        ...mapMutations('live', ['setVideoPlayStatus']),
        initVideo() {
            this.video = videojs('video', {
                // 兼容 半屏 播放
                playsinline: true,
                preload: "auto",
                "webkit-playsinline": true,
                "x5-playsinline": true,
                "x-webkit-airplay": true,
                "x5-video-player-type": "h5",
                "x5-video-player-fullscreen": false,
                "x5-video-orientation": "landscape|portrait",
                sources: [{ src: this.source }],
                autoplay: this.autoplay,
                loop: this.rePlay
            }, () => {
                // $(".vjs-poster").remove();
                // $(".vjs-control-bar").remove();
                // $(".vjs-text-track-display").remove();
                // $(".vjs-loading-spinner").remove();
                // $(".vjs-big-play-button").remove();
                // $(".vjs-error-display").remove();
                // $(".vjs-modal-dialog").remove();
                // $("#video").removeClass(
                //     "vjs-paused vjs-controls-disabled vjs-v6 " +
                //     "vjs-user-inactive vjs-workinghover video-dimensions vjs-has-started"
                // );

                // Fired when the user agent begins looking for media data
                this.video.on('loadstart', this.onLoadStart);
                // Fires when the browser has loaded meta data for the audio/video.
                this.video.on('loadedmetadata', this.onLoadedmetadata);

                this.video.on('durationchange', this.onDurationchange);
                // The media has a readyState of HAVE_FUTURE_DATA or greater.
                this.video.on('canplay', this.onCanplay);
                // Triggered whenever an Tech#play event happens. Indicates that playback has started or resumed.
                this.video.on('play', this.onPlay);
                // The media is no longer blocked from playback, and has started playing.
                this.video.on('playing', this.onPlaying);
                // Fired whenever the media has been paused
                this.video.on('pause', this.onPause);
                // A readyState change on the DOM element has caused playback to stop.
                this.video.on('waiting', this.onWaiting);
                // Fires when the current playback position has changed.
                this.video.on('timeupdate', this.onTimeupdate);
                // Fired whenever the player is jumping to a new time
                this.video.on('seeking', this.onSeeking);
                // Fired when the player has finished jumping to a new time
                this.video.on('seeked', this.onSeeked);
                // Fired when the end of the media resource is reached (currentTime == duration)
                this.video.on('ended', this.onEnded);
                // Fires when the loading of an audio/video is aborted.
                this.video.on('abort', () => { });
                // Fires when the browser is trying to get media data, but data is not available.
                this.video.on('stalled', this.onStalled);
                // Fires when the browser is intentionally not getting media data.
                this.video.on('suspend', this.onSuspend);
                // Set or get the current MediaError
                this.video.on('error', this.onError);
                // Called when the player is being disposed of.
                this.video.on('dispose', () => { });
            });
        },

        onEvent() {
            this.$player.addListener('setPlay', this.onSetPlay);
            this.$player.addListener('progress', this.onProgress);
            this.$player.addListener('setSpeed', this.onSetSpeed);
            this.$player.addListener('setMuted', this.onSetMuted);
            this.$player.addListener('refresh', this.onRefresh);
        },

        offEvent() {
            this.$player.removeListener('setPlay', this.onSetPlay);
            this.$player.removeListener('progress', this.onProgress);
            this.$player.removeListener('setSpeed', this.onSetSpeed);
            this.$player.removeListener('setMuted', this.onSetMuted);
            this.$player.removeListener('refresh', this.onRefresh);
        },

        onLoadStart() {
            console.log('loadstart');
            this.$player.dispatchEvent('loadstart');
        },

        onLoadedmetadata() {
            console.log('loadedmetadata');
            if (this.startTime) { this.video.currentTime(this.startTime) }

            this.$player.dispatchEvent('loadedmetadata');
        },

        onDurationchange() {
            let duration = this.video.duration();
            this.$player.dispatchEvent('duration', duration);
        },

        onCanplay() {
            console.log('canplay')
            this.$player.dispatchEvent('canplay');
        },

        onPlay() {
            console.log('play');
	        this.setVideoPlayStatus(true)
	        this.$player.dispatchEvent('play', true);
        },

        onPlaying() {
            console.log('playing');
            this.$player.dispatchEvent('play', true);
        },

        onPause() {
            console.log('pause');
	        this.setVideoPlayStatus(false)
	        this.$player.dispatchEvent('play', false);
        },

        onWaiting() {
            console.log('waiting');
            this.$player.dispatchEvent('waiting');
        },

        onTimeupdate() {
            let currTime = this.video.currentTime();
            let buffered = this.video.bufferedEnd();
            let bufferedPercent = this.video.bufferedPercent();
            this.$player.dispatchEvent('timeupdate', currTime, buffered, bufferedPercent);
        },

        onSeeking() {
            console.log('seeking');
            this.$player.dispatchEvent('seeking');
        },

        onSeeked() {
            console.log('seeked');
            this.$player.dispatchEvent('seeked');
        },

        onEnded() {
            console.log('ended')

            this.$player.dispatchEvent('ended');
        },

        onStalled() {
            console.log('stalled');

            this.$player.dispatchEvent('stalled');
        },

        onSuspend() {
            console.log('suspend')
        },

        onError() {
            let err = this.video.error();
            let msg = '';

            console.log('error', err);

            /**
             * MediaError
             *
             * 0 MEDIA_ERR_CUSTOM
             * 1 MEDIA_ERR_ABORTED
             * 2 MEDIA_ERR_NETWORK
             * 3 MEDIA_ERR_DECODE
             * 4 MEDIA_ERR_SRC_NOT_SUPPORTED
             * 5 MEDIA_ERR_ENCRYPTED
             */

            switch (err.code) {
                case 1: msg = '视频停止'; break;
                case 2: msg = '网络异常'; break;
                case 3: msg = '视频解码失败'; break;
                case 4: msg = '不支持的视频源'; break;
                case 5: msg = '视频加密无法播放'; break;
                default: msg = '视频播放失败';
            }

            this.$player.dispatchEvent('error', err.code, msg);
        },

        onSetPlay(isPlay) {
	        isPlay ? this.video.play() : this.video.pause();
        },

        onProgress(currTime) {
            this.video.currentTime(currTime);

            let duration = this.video.duration();

            if (currTime >= duration) {
                this.video.pause();
            }
        },

        onSetSpeed(speed) {
            this.video.playbackRate(speed)
        },

        onSetMuted(isMute) {
            this.video.muted(isMute);
        },

        onRefresh() {
            this.video.src(this.source)
        }
    }
}
</script>
<style lang="less" scoped>
.c-player__video {
    width: 100%;
    height: 100%;
    // background: #1e1e20;

    #video,
    video {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
        z-index: 1;
    }

    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
    }
}
</style>