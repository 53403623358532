const mainState = {
    // 用户信息
    userInfo: null,

    // 登录票据
    userAuth: null,

    // 微信jssdk获取配置的地址
    wxConfigUrl: '',

    keepAlive: [],

    // customInfo 全局自定义属性配置
    customeInfo: {
        color: '',
        isIframe: 0
    }
}

export default mainState;
