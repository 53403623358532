const GOLDSUN = 'goldSun';
//local
export const setStorage = (key, value, type = '') => {
    const typeName = getTypeName(type);
    let keyName = typeName ? `${typeName}-${key}` : key;
    localStorage.setItem(keyName, value);
}

export const getStorage = (key, type = '') => {
    const typeName = getTypeName(type);
    let keyName = typeName ? `${typeName}-${key}` : key;
    return localStorage.getItem(keyName);
}
// session
export const setSessionStorage = (key, value, type = '') => {
    const typeName = getTypeName(type);
    let keyName = typeName ? `${typeName}-${key}` : key;
    sessionStorage.setItem(keyName, value);
}

export const getSessionStorage = (key, type = '') => {
    const typeName = getTypeName(type);
    let keyName = typeName ? `${typeName}-${key}` : key;
    return sessionStorage.getItem(keyName);
}

const getTypeName = (type) => {
    switch(type) {
        case GOLDSUN:
            return 'goldSun';
        default:
            return '';
    }
}

export const saveGoldSunUserId = (val) => {
    setStorage('userId', val, GOLDSUN);
}
export const getGoldSunUserId = () => {
    const val = getStorage('userId', GOLDSUN);
    return val ? val : '';
}

export const saveRecordSave = (val) => {
    const str = val ? JSON.stringify(val) : null;
    setStorage('recordSave', str, GOLDSUN);
}
export const getRecordSave = () => {
    const val = getStorage('recordSave', GOLDSUN);
    return val ? JSON.parse(val) : null;
}

