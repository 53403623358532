import Vue from 'vue'
import Vuex from 'vuex'
import state from './state'
import mutations from './mutations'
import * as getters from './getters'
import * as actions from './actions'
import live from './modules/live';
Vue.use(Vuex);

const Store = new Vuex.Store({
	state,
	mutations,
	getters,
	actions,
    modules: {
        live 
    },
	strict: false
});

export default Store