import sensors from "../assets/sdk/sensorsdata.min.js";
// import sensors from 'sa-sdk-javascript'
sensors.init({
    // 神策系统配置
    sdk_url:
        "https://goldsundg.guosen.com.cn:1445/asset/common/js/sensorsdata.min.js",
    name: "sensors", //设置名为"sensors"的标识。
    server_url: "https://sensor.guosen.com.cn/sa?project=default", // 数据接收地址
    app_js_bridge: true, //app打通
    is_track_single_page: true, // 单页应用页面浏览事件采集(url改变就触发)
    use_app_track: true,
    show_log: true, // 控制台显示数据开
    heatmap: {
        // 热图设置 default开启 not_collect关闭
        //是否开启点击图，default 表示开启，自动采集 $Webclick 事件，可以设置'not_collect’表示关闭。
        clickmap: "default", // 点击热图，收集点击事件
        //是否开启触达注意力图，not_collect 表示关闭，不会自动采集$Webstay 事件，可以设置'default'表示开启。
        scroll_notice_map: "default", // 视区热图，收集页面区域停留时间
    },
});

//设置公共属性
sensors.registerPage({
    platform_type: "H5", //指定类型H5
    current_url: location.href, //记录当前URL地址；
    referrer: document.referrer, // 获取并发送referrer（来源页面地址）
    source: 1, //来源
    brand: 1, //品牌标识
});



// 全埋点
// ① 自动采集页面浏览事件 $pageview
// ② 自动采集页面点击事件 $WebClick
// ③ 自动采集页面停留时间 $WebStay
sensors.quick("autoTrack"); 
export default sensors;
