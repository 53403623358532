// 定义仓库变量
// 按类划分
export default {
	/** 加载状态 **/
	loading: false,

	/** 用户同意协议 **/
	agreement_box: false,

	/**用户信息（用于同意协议）**/
	loginPara: {},

	/*回放私密密码 */
	//   record_verify:''
	// /**用户同意协议类型（绑定手机号/新注册）**/
	// agreeType: '',

	// 路演厅用户信息
	user: null,

	// 路演详情数据
	roadshowData: {},

	// 预告/回放
	isLiveRoadshow: false,

	// 是否直播中
	isLive: false,

	// 直播类型
	liveMode: null,

	// 直播厅 举手状态
	raiseStatus: "none", // none wait speak

	// 演示文稿
    pdfList: [],
	pdfData: null,

	videoList: [],

	/* 私密路演的私密密码 */
	verifyPassword: "",

	/* 章节时间点 */
    chapterTime: '',

	// 直播间消息队列
    events: [],

	// 回放当前时间
	recordCurrTime: '',

	// 播放状态
	videoPlayStatus: false,

	// 用户信息
	userInfo: null
}
