<template>
    <div
       style="width:100%;max-height:100%;"
       ref="refDiv"
   ></div>
</template>
<script>
import lottie from "lottie-web";
export default {
    props: {
        data: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            anim: null
        }
    },
    mounted() {
        const element = this.$refs.refDiv;
        const dataJson = this.data;
        this.anim = lottie.loadAnimation({
            container: element, // the dom element that will contain the this.anim;this.$refs.lottieContainer
            renderer: 'svg', // the renderer
            loop: true,
            autoplay: true,
            animationData: dataJson,
        });
        this.anim.addEventListener('data_ready', () => {
            console.log('动画数据加载完毕')
        }) // 动画数据加载完毕
        this.anim.addEventListener('config_ready', () => {
            console.log('完成初始配置')
        }) // 完成初始配置后
        this.anim.addEventListener('data_failed', () => {
            console.log('加载动画数据失败')
        }) // 加载动画数据失败
        this.anim.addEventListener('loaded_images', () => {
            console.log('所有图片加载成功或者失败')
        }) // 所有图片加载成功或者失败
        this.anim.addEventListener('DOMLoaded', () => {
            console.log('将元素添加到DOM后')
        }) // 将元素添加到DOM后
    },
    unmounted () {
        this.anim && this.anim.destroy()
    }
}
</script>
