export const isBuild = /(https|http):\/\/(yjfw\.guosen\.com\.cn)/i.test(location.href.toLowerCase());
const URL_MAP = new Map([
    [
        'test', {
            goldSun_analyst: 'https://lizhilicai.guosen.com.cn/xtgzstg/institute/dist/pages/analyst.html'
        }
    ],
    [
        'build', {
            goldSun_analyst: 'https://zstgmsg.guosen.com.cn/xtgzstg/institute/dist/pages/analyst.html'
        }
    ]
]);
export const SRS_URL = window.origin;
export const URL = isBuild ? URL_MAP.get('build') : URL_MAP.get('test');

// 测试: https://lizhilicai.guosen.com.cn/xtgzstg/institute/dist/pages/analyst.html?notesid=xxxx
// 生产：https://zstgmsg.guosen.com.cn/xtgzstg/institute/dist/pages/analyst.html?notesid=xxxx
