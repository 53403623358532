// 同步改变state
// 按类划分
// 命名：show/hide、get/set、add/reduce...

export default {
	// 播放器路演数据
	setRoadshowData(state, v) {
		state.roadshowData = v
	},
    // 设置pdf数据
    setPdfList(state, list) {
        state.pdfList = list
    },
	setPdfData(state, v) {
		state.pdfData = v
	},

	/* 设置章节时间点 */
	setChapterTime(state, time) {
		state.chapterTime = time;
	},

	/* 设置回放当前时间 */
	setRecordCurrTime(state, time) {
		state.recordCurrTime = time;
	},

	setVideoPlayStatus(state, status) {
		state.videoPlayStatus = status
	},
    // 直播类型：音频/视频
	setLiveMode(state, mode) {
		state.liveMode = mode;
	},
}
