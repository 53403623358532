export const fetchPdfImage = (ctx, obj) => {
	fetch({
		constant_key: 'json_roadshow_attachmentpiclist',
		body: {
			attachmentid: Number(obj.pid)
		},
		success: res => {
			obj.success && obj.success(res);
		}
	})
}