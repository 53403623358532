<!--  -->
<template>
    <div class="road_list">
        <div class="road_ul" v-for="(item, index) in roadList" :key="item.id" @click="clickMeeting(item.id)">
            <div class="road_left">
                <img class="web_img" :src="item.logoweb" alt="">
                <div class="status_img" v-if="[1, 2].includes(item.tag)">
                    {{ item.tag === 1 ? '限时回放' : '回放' }}
                </div>
                <div class="road_time" v-if="item.startTime">{{ item.startTime | timeFilter }}</div>
            </div>
            <div class="road_right">
                <div class="road_title">{{ item.title }}</div>
                <div class="road_relevance" :ref="`road${index}`">
                    <div class="relevance_t" v-for="(i, itemIndex) in item.stock" :key="itemIndex"
                        :ref="`item${itemIndex}${index}`">{{ i }}</div>
                </div>
                <div class="name_play">
                    <div class="time_text">
                        <span class="title_time_name">{{ item.analystName }}</span>
                    </div>
                    <div class="time_play">
                        <img src="../../assets/img/play.png" alt="" class="time_play_img">
                        <span class="play_num"> {{ item.view }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import dayjs from 'dayjs';
export default {
    name: "",
    props: {
        roadList: {
            type: Array,
            default: () => []
        },
    },
    data() {
        return {

        };
    },
    filters: {
        timeFilter(time) {
            return time ? dayjs(time).format('MM-DD hh:mm') : ''
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.checkChildWidths();
        })
    },
    methods: {
        clickMeeting(id) {
            this.$emit("changeMeeting", id);
        },
        checkChildWidths() {
            this.roadList.forEach((item, index) => {
                const parent = this.$refs['road' + index][0];
                let totalChildWidth = 0;
                let firstTruncatedChildIndex = -1;
                let foundFirstTruncatedChildIndex = false;

                if (item.relevance && Array.isArray(item.relevance)) {
                    item.relevance.forEach((_, itemIndex) => {
                        const child = this.$refs['item' + itemIndex + index]?.[0];
                        if (child) {
                            totalChildWidth += child.offsetWidth;

                            if (totalChildWidth > parent.offsetWidth && !foundFirstTruncatedChildIndex) {
                                firstTruncatedChildIndex = itemIndex;
                                foundFirstTruncatedChildIndex = true;
                            }
                        }
                    });
                }

                if (firstTruncatedChildIndex !== -1) {
                    let truncatedRelevance = [...item.relevance];
                    truncatedRelevance[firstTruncatedChildIndex] = '...';
                    this.$set(item, 'relevance', truncatedRelevance);

                    // 更新DOM
                    item.relevance.forEach((value, itemIndex) => {
                        const child = this.$refs['item' + itemIndex + index][0];
                        child.innerText = value;
                    });
                }
            });
        },
    }
};
</script>
<style lang="less" scoped>
.road_ul {
    display: grid;
    grid-template-columns: 1.12rem 1fr;
    column-gap: 0.1rem;

    // border-bottom: 0.05px solid #DFE1E6;
    // overflow: hidden;
    &+.road_ul {
        margin-top: 0.17rem;
    }
}

.road_left {
    position: relative;
    width: 100%;
    height: .88rem;
    margin-right: .1rem;

    .status_img {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 0.11rem;
        line-height: 0.15rem;
        font-weight: 400;
        padding: 0 0.04rem;
        border-radius: 0.02rem 0;
        background-image: linear-gradient(145deg, #777777 0%, #454546 100%);
        color: #fff;
    }

    .web_img {
        display: block;
        width: 100%;
        height: 100%;
        border-radius: .04rem;
    }

    .road_time {
        position: absolute;
        font-size: .1rem;
        color: #FFFFFF;
        left: .03rem;
        bottom: .04rem;
        font-size: 0.08rem;
        line-height: 0.12rem;
        width: 0.54rem;
        text-align: center;
        background: rgba(0, 0, 0, 0.5);
    }
}


.road_right {
    position: relative;

    .road_title {
        // max-width: 1.78rem;
        font-size: .14rem;
        color: #212634;
        line-height: .19rem;
        margin-bottom: .08rem;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .name_play {
        position: absolute;
        bottom: 0.04rem;
        left: 0;
        right: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.road_relevance {
    display: flex;
    max-width: 1.78rem;
    // margin-bottom: .12rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    .relevance_t {
        background: #EFF4FF;
        font-size: .1rem;
        margin-right: .04rem;
        color: #447EF2;
        padding: .015rem .04rem;
        border-radius: .002rem;
    }

    .relevance_t:last-child {
        margin-right: 0;
    }
}



.time_text {
    font-size: .11rem;
    color: #677288;
}

.title_time_status {
    padding: .02rem .04rem;
    border: 1px solid #939AAA;
    border-radius: 4px;
}

.time_play {
    display: flex;
    align-items: center;

    .time_play_img {
        width: 0.15rem;
        height: 0.15rem;
        margin-bottom: 0.01rem;
    }

    .play_num {
        font-size: .11rem;
        color: #677288;
    }
}
</style>
