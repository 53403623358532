<!--  -->
<template>
    <div class="gold-sun">
        <c-player :data="roadshowData" :pdfPages="pdfData" v-if="status === 'ended'" />
        <div v-else class="video_box">
            <img :src="roadshowData.logo" />
            <div v-if="status === 'loading'" class="video_box__loading">回放生成中, 请稍后关注</div>
        </div>
        <div class="appointment_box" v-if="status === 'coming'">
            <div class="appointment_time">{{ startTime | timeFilter }} 开播 </div>
            <div class="appointment_text">预约后将在开播当天提醒具体的开播时间</div>
            <div class="appointment_btu" :class="{ 'appointment_btu_bgc': isorder }" @click="clickAppointment">
                <span>{{ isorder ? '已' : '' }}预约</span>
            </div>
        </div>
        <div class="title_info">
            <div class="title">{{ title }}</div>
            <div class="title_ind">
                <span v-for="(item, index) in srsRoadshowStockRelsList" :key="index" class="span_ind">
                    {{ item }}
                </span>
            </div>
            <div class="title_time">
                <div class="time_text">
                    <span class="title_time_name">{{ employee }} | {{ startTime }}</span>
                    <span class="title_time_status" v-if="tag === 1">限时回放 </span>
                </div>
                <div class="time_play">
                    <img src="../../assets/img/play.png" alt="" class="time_play_img">
                    <span class="play_num">{{ viewNum }}</span>
                </div>
            </div>
            <div class="analyst_info" v-if="isAnalyst">
                <div class="analyst_left" @click.stop="handleLookAnalyst">
                    <img :src="analystForm.img" alt="" class="analyst_img">
                    <div class="analyst_name">
                        <span class="analyst_name_t">{{ analystForm.name }}</span>
                        <span class="analyst_option_t">{{ analystForm.industry }}&{{ analystForm.position }}</span>
                    </div>
                </div>
                <div class="analyst_right" :style="{ borderColor: !isfollow ? '#2F569E' : '#DFE1E6' }"
                    @click.stop="clickFollowAnalyst">
                    <img src="../../assets/img/add1.png" alt="" class="r_img" v-if="!isfollow">
                    <span class="a_text" :style="{
                        color: !isfollow ? '#2F569E' : '#939AAA'
                    }">{{
                            !isfollow ? '关注' : '已关注' }}
                    </span>
                </div>
            </div>
        </div>
        <div class="roadshow" v-if="guestList.length > 0 || compereList.length > 0 || sumup">
            <!-- <div v-if="introduceList.length > 0" class="roadshow_item">
                <div class="title_road title_road__intro">路演介绍</div>
                <div class="introduce_title" v-for="item in introduceList" :key="item.id">
                    <div v-html="item.content"></div>
                </div>
            </div> -->
            <div v-if="guestList.length > 0" class="roadshow_item">
                <div class="title_road">嘉宾介绍</div>
                <div class="introduce_title">
                    <div class="title_road_honored-guest" v-for="(item, idx) in guestList" :key="idx">
                        <span class="guest_name">{{ item.name }}</span>
                        <span v-if="item.position">（{{ item.position }}）</span>
                    </div>
                </div>
            </div>
            <div v-if="compereList.length > 0" class="roadshow_item">
                <div class="title_road">主持人</div>
                <div class="introduce_title">
                    <div class="title_road_honored-guest" v-for="(item, index) in compereList" :key="index">
                        <span class="guest_name">{{ item.name }}</span>
                        <span v-if="item.position">（{{ item.position }}）</span>
                    </div>
                </div>
            </div>
            <!-- <div class="roadshow_item" v-if="sumup">
                <div class="title_road title_road__intro">AI总结</div>
                <div class="introduce_title" v-html="sumup"></div>
            </div> -->

            <div class="roadshow_item" @click="changeSummaryRouter()" v-if="sumup">
                <img src="@img/meeting.png" class="banner" />
            </div>
        </div>
        <div class="meeting" v-if="roadList && roadList.length > 0">
            <div class="meeting_road">相关会议</div>
            <roadItem :roadList="roadList" @changeMeeting="changeMeeting">
            </roadItem>
        </div>
        <div class="disclaimer">
            <p>【免责声明】本页面内容由国信证券股份有限公司提供，国信证券依据交易所公开数据以及外购或自有的行情数据源作为计算的基础数据，本公司会尽力但并不保证基础数据的及时性准确性、真实性和完整性，不承担因任何数据不准确或选漏等造成的任何损失或损害的责任。投资者依据相关信息进行投资操作时，应当自主进行判断，所导致的盈亏由投资者自行承担，国信证券不承担任何法律责任。浏览本页面或使用本功能即表示投资者同意所载免责声明。
            </p>
            <p>路演会议由国信证券股份有限公司提供。在会议中，国信证券分享的研究成果和专业观点仅供投资者个人参考和使用。为保障信息的专属性和权威性，未经国信证券书面许可，敬请勿进行录音、录像或将相关内容用于任何用途或对外传播。国信证券以专业的技能和谨慎的态度提供研究成果，研究成果仅供投资者在投资决策时参考，国信证券对于投资者根据这些信息所作出的投资决策不承担任何法律责任。请投资者充分认识投资风险，审慎决策。任何依据路演会议所做的投资活动所产生的收益和可能的损失，都将由投资者个人承担。
            </p>
        </div>
    </div>
</template>

<script>
import { onCallNative } from '@/utils/communication';
import CPlayer from "@/components/live/c-player.vue";
import player, { playerMixin } from "@/components/live/c-player.js";
import { goldSun } from "@/axios/interface";
import roadItem from './road-list.vue';
import dayjs from 'dayjs';
import { saveGoldSunUserId } from '@/utils/storage';
import { URL } from '@/axios/host';
import sensorsTrack from '@/utils/sendSensors.js'
export default {
    name: "",
    components: {
        roadItem, CPlayer
    },
    mixins: [playerMixin],
    data() {
        return {
            sumup: '',
            viewNum: 0,
            roadshowData: {},
            pdfData: [],
            srsRoadshowStockRelsList: [],
            isfollow: false, // 关注
            guestList: [],
            compereList: [],
            roadList: [],
            // introduceList: [], // 路演介绍
            title: '',
            employee: '',
            startTime: '',
            analystForm: {
                img: '',
                name: '',
                industry: '',
                position: ''
            },
            isAnalyst: false, // 是否有分析师
            isorder: false, // 是否预约
            mobile: '',
            followLoading: false,
            orderLoading: false,
            tag: null, // 1 限时  2不限时
            status: 'ended', // loading 生成中 coming 预约  ended 回放
            datas: {},

        };
    },
    created() {
        onCallNative('WebContainer', 'titleItem', { 'title': '路演会议', 'bgColor': 'ffffff', 'titleColor': '212634' });
        player.register();
    },
    mounted() {
        this.hanldcheckAccess();
        // this.getData();

    },
    filters: {
        timeFilter(time) {
            if (!time) return '';
            let formatText = 'MM月DD日';
            return dayjs(time).format(formatText);
        }
    },
    methods: {
        // 查询身份信息
        hanldcheckAccess() {
            this.$axios.post(goldSun.checkAccess, {
                id: this.$route.query.id,
                token: this.$route.query.token
            }, { bodyType: 'form' }).then(res => {
               
                this.isfollow = +res.data.isfollow === 1;
                this.isorder = +res.data.isorder === 1;
                this.viewNum = res.data.view || 0;
                this.roadList = (res.data.meetings || []).map(attr => {
                    const industryArr = attr.researchName ? attr.researchName.split(',') : [];
                    const stockArr = attr.stock ? JSON.parse(attr.stock).map(attr => attr.stock_name) : [];
                    return {
                        ...attr,
                        stock: [
                            ...industryArr,
                            ...stockArr
                        ]
                    }
                });
                console.log(this.roadList, '相关会议列表');

                const { tag, status, mobile, starttime } = res.data || {};
                this.tag = tag;
                this.mobile = mobile;
                this.startTime = starttime;
                this.getData().then(() => {
                    const { pageAudioUrl } = this.roadshowData;
                    let _status = 'loading';
                    if (status === 0) {
                        _status = 'coming';
                    } else if (status === 3 && [1, 2].includes(tag) && pageAudioUrl) {
                        _status = 'ended';
                    }
                    this.status = _status;
                    if (this.status === 'ended') {
                        saveGoldSunUserId(this.mobile);
                        this.getVideoData();
                    }
                })
            }, () => {
            })
        },
        getData() {
            // 获取数据
            return this.$axios.post(goldSun.getExternal, {
                id: this.$route.query.id
            }, { bodyType: 'form' }).then(res => {
                const data = res.data;
                if (data) {
                    this.setRoadshowData(data);
                    this.datas = data
                    localStorage.setItem('myData', JSON.stringify(this.datas));
                    this.title = data.title;
                    this.$trackPageView({
                        requirement_id:1391,
                        track_sign: "aInvestmentConsultant.b8480.pageview.PageView",
                        content_title: data.title || '路演会议'
                    });
                    this.employee = data.employee;
                    this.sumup = data.srsRoadshowSummary && +data.srsRoadshowSummary.isPublish === 1
                        ? data.srsRoadshowSummary.summary
                        : '';
                    if (data.staffInfoDtos && data.staffInfoDtos.length > 0) {
                        this.analystForm.img = data.staffInfoDtos[0].photoUrl;
                        this.analystForm.name = data.staffInfoDtos[0].name;
                        this.analystForm.position = data.staffInfoDtos[0].identityValue;
                        this.analystForm.industry = data.staffInfoDtos[0].industryValue;
                        this.analystForm.id = data.staffInfoDtos[0].externalId;
                        this.isAnalyst = !!this.analystForm.id;
                    } else {
                        this.isAnalyst = false;
                    }
                    const industryStr = data.staffInfoDtos && data.staffInfoDtos.length
                        ? data.staffInfoDtos[0].industryValue
                        : '';
                    const industryArr = industryStr ? industryStr.split(',') : [];
                    const srsRoadshowStockArr = (data.srsRoadshowStockRels || []).map(item => item.stockName);
                    this.srsRoadshowStockRelsList = [
                        ...industryArr,
                        ...srsRoadshowStockArr
                    ];
                    // this.introduceList = data.introduce || [];
                    this.guestList = data.distGust || [];
                    this.compereList = data.speaker || [];
                } else {
                    this.isAnalyst = false;
                }
            }, () => { })
        },
        setRoadshowData(obj) {
            const roadshowData = {
                routerId: this.$route.query.id,
                id: obj.roadshowId,
                logo: obj.logo,
                pageAudioUrl: obj.recordUrl,
                title: obj.title,
                syncppt: 0,
                eventWay: obj.eventWay || 0 //0，音频直播  1， 视频直播
            };
            this.roadshowData = roadshowData;
        },
        async getVideoData() {
            const roadshowData = this.roadshowData;
            let pdfData = [];
            await this.$axios.post(goldSun.getPptInfo, {
                roadshowId: roadshowData.id
            }, { bodyType: 'form' }).then(res => {
                roadshowData.syncppt = res.data && res.data.length ? 1 : 0;
                if (roadshowData.syncppt === 1) pdfData = res.data;
            }, () => { });
            this.roadshowData = roadshowData;
            this.pdfData = pdfData;
        },
        getLabel() {
            // 获取路演标签
            return this.$axios.post(goldSun.getLabel, {
                id: this.$route.query.id,
            }, { bodyType: 'form' })
        },
        async clickFollowAnalyst() {
            sensorsTrack('clickFollowAnalyst', callback()) 
            if (this.followLoading) return;
            if(!this.isfollow){
                this.$trackClick({
                    requirement_id:1391, 
                    track_sign: "aInvestmentConsultant.b8480.c8482.click.PageClick"
                    // [定义属性名]:  this.analystForm.id+'-'+this.analystForm.name
                })
            }
            this.followLoading = true;
            await this.$axios.post(goldSun.followAnalyst, {
                mobile: this.mobile,
                notesid: this.analystForm.id || '',
                action: this.isfollow ? 0 : 1,
            }, { bodyType: 'form' }).then(() => {
                const toast = this.isfollow ? '取消成功' : '关注成功';
                this.isfollow = !this.isfollow;
                this.$showToast(toast);
            });
            this.followLoading = false;
        },
        async clickAppointment() {
            if (this.orderLoading) return;
            this.orderLoading = true;
            await this.$axios.post(goldSun.followMeeting, {
                mobile: this.mobile,
                action: this.isorder ? 0 : 1,
                id: this.$route.query.id
            }, { bodyType: 'form' }).then(() => {
                const toast = this.isorder ? '取消成功' : '预约成功';
                this.isorder = !this.isorder;
                this.$showToast(toast);
            })
            this.orderLoading = false;
        },
        changeMeeting(val) {
            this.$trackClick({
                requirement_id:1391, 
                track_sign: "aInvestmentConsultant.b8480.c8483.click.PageClick"
                 // [定义属性名]: val
            })
            this.$router.push({
                path: "/goldSun/goldSun_router", query: { id: val, token: this.$route.query.token }
            });
            location.reload();
        },
        handleLookAnalyst() {
            window.location.href = URL.goldSun_analyst + '?notesid=' + this.analystForm.id;
        },
        changeSummaryRouter(val) {
            // 总结埋点
            this.$trackClick({
                requirement_id:1391, 
                track_sign: "aInvestmentConsultant.b8480.c8481.click.PageClick"
                 // [定义属性名]: this.$route.query.id+'-'+this.title
            })
            this.$router.push({
                path: "/goldSun/summary_router", query: { id: val, token: this.$route.query.token, }
            });

        }

    },
};
</script>
<style lang="less" scoped>
/* @import url(); 引入css类 */
.gold-sun {
    min-height: 100vh;
    font-size: .16rem;
    background: #F4F5F6;
}

.video_box {
    position: relative;
    width: 100%;
    height: 2.11rem;
    background-color: #0288bb;

    &__loading {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.18rem;
        color: #fff;
        background-color: rgba(0, 0, 0, .8);
    }

    img {
        display: block;
        width: 100%;
        height: 100%;
    }
}

.appointment_box {
    width: 100%;
    height: 1.3rem;
    text-align: center;
    background-image: url("../../assets/img/appintment_box.png");
    background-size: 100% 100%;
}

.appointment_time {
    padding-top: .16rem;
    font-size: .18rem;
    color: #212634;
    font-weight: bold;
}

.appointment_text {
    margin-top: .17rem;
    font-size: .12rem;
    color: #677288;
}

.appointment_btu {
    width: 1.2rem;
    height: .32rem;
    margin: 0 auto;
    margin-top: .19rem;
    font-size: .15rem;
    line-height: .32rem;
    color: #fff;
    background: #2F569E;
    border-radius: .16rem;
}

.appointment_btu_bgc {
    background: rgba(47, 86, 158, 0.50);
}

.title_info {
    margin-top: .1rem;
    margin-bottom: .1rem;
    padding: .12rem .15rem .15rem .15rem;
    background: #FFFFFF;
}

.title {
    font-size: .16rem;
    color: #212634;
    line-height: .23rem;
    font-weight: 400;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.title_ind {
    padding: 0.07rem 0 0.07rem 0;
}

.span_ind {
    display: inline-block;
    font-size: .1rem;
    color: #447EF2;
    font-weight: 400;
    background: #EFF4FF;
    border-radius: 4px;
    padding: 0.035rem 0.05rem;
    margin: 0 0.04rem 0.0rem 0;
}


.title_time {
    height: .22rem;
    line-height: .22rem;
    margin-bottom: .26rem;
    display: flex;
    justify-content: space-between;
}

.time_text {
    display: flex;
    align-items: center;
    font-size: .11rem;
    color: #677288;
}

.title_time_name {
    margin-right: .07rem;
}

.title_time_status {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 0.48rem;
    height: 0.15rem;
    font-size: 0.1rem;
    border: 0.05px solid #939AAA;
    border-radius: 0.02rem;
}

.time_play_img {
    display: inline-block;
    width: 0.15rem;
    height: 0.15rem;
    vertical-align: middle;
}

.play_num {
    font-size: .11rem;
    color: #677288;
}

.analyst_info {
    width: 100%;
    min-height: .61rem;
    padding: .1rem .15rem;
    background: #F0F7FF;
    border-radius: 8px;
    display: grid;
    grid-template-columns: auto 0.6rem;
    column-gap: 0.1rem;
    // align-items: center;
}

.analyst_left {
    display: grid;
    grid-template-columns: 0.4rem auto;
    column-gap: 0.125rem;

    .analyst_img {
        width: 100%;
        height: 0.4rem;
        border-radius: 50%;
    }
}

.analyst_right {
    width: 100%;
    height: .26rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #2F569E;
    border-radius: 0.13rem;
    margin-top: 0.07rem;

    .r_img {
        width: .09rem;
        height: .09rem;
        margin-right: .03rem;
        margin-bottom: 0.01rem;
    }

    .a_text {
        font-size: .12rem;
        color: #2F569E;
    }
}

.analyst_name {
    display: flex;
    flex-flow: column;
}

.analyst_name_t {
    font-size: .15rem;
    color: #212634;
    font-weight: bold;
    margin-bottom: .08rem;
}

.analyst_option_t {
    font-size: .12rem;
    line-height: 0.15rem;
    color: #677289;
}

// roadshow
.roadshow {
    padding: .2rem .15rem;
    margin-bottom: .1rem;
    background-color: #FFFFFF;

    &_item {
        &+.roadshow_item {
            margin-top: .18rem;
        }

        .title_road {
            font-size: .15rem;
            color: #212634;
            line-height: .18rem;
            font-weight: bold;
            margin-bottom: .10rem;

            &.title_road__intro {
                margin-bottom: 0.06rem;
            }
        }

        .introduce_title {
            font-size: .14rem;
            color: #677288;
            line-height: .19rem;

            /deep/ ul {
                li {
                    list-style: disc !important;
                }
            }

            /deep/ ol {
                li {
                    list-style: decimal !important;
                }
            }

            /deep/ li {
                margin-left: 15px;
            }
        }
    }
}

.title_road_honored-guest {
    margin-bottom: .05rem;

    .guest_name {
        font-size: .14rem;
        color: #212634;
    }
}

.meeting {
    padding: .15rem .15rem 0.17rem 0.15rem;
    background-color: #FFFFFF;

    &_road {
        font-size: 0.15rem;
        color: #212634;
        line-height: 0.18rem;
        font-weight: bold;
        margin-bottom: 0.16rem;
    }
}

.disclaimer {
    padding: .17rem .15rem;
    font-size: .12rem;
    color: #939AAA;
    font-weight: 400;
    line-height: .2rem;
}
</style>
