import Vue from 'vue'
import VueRouter from 'vue-router'

//引入刚刚写的autoRouter.js文件
import routerArr from './autorouter'
console.log('routerArr', routerArr);
Vue.use(VueRouter)
const routes = [
  //这里是其他手动写的路由
  {
    path: '/',
    component: () => import('@/views/goldSun/goldSun_router'),
    meta: {
      title: '金太阳'
    },
  }
]

const router = new VueRouter({
  mode: 'history',
  //这里进行路由合并
  routes: [...routes, ...routerArr]
})

export default router
